/**
 * @readonly
 * @property {Object} DaysOfWeek 
 * DaysOfWeek definitions 
 */
const DaysOfWeek = {}
let prefix = 'sayl.day_';

let MONDAY = {
  label: `${prefix}monday`,
  value: 1,
}
DaysOfWeek.MONDAY = MONDAY

let TUESDAY = {
  label: `${prefix}tuesday`,
  value: 2,
}
DaysOfWeek.TUESDAY = TUESDAY

let WEDNESDAY = {
  label: `${prefix}wednesday`,
  value: 3,
}
DaysOfWeek.WEDNESDAY = WEDNESDAY

let THURSDAY = {
  label: `${prefix}thursday`,
  value: 4,
}
DaysOfWeek.THURSDAY = THURSDAY

let FRIDAY = {
  label: `${prefix}friday`,
  value: 5,
}
DaysOfWeek.FRIDAY = FRIDAY

let SATURDAY = {
  label: `${prefix}saturday`,
  value: 6,
}
DaysOfWeek.SATURDAY = SATURDAY

let SUNDAY = {
  label: `${prefix}sunday`,
  value: 7,
}
DaysOfWeek.SUNDAY = SUNDAY

/**
 * @readonly
 * @property {Object} ALL 
 * DaysOfWeek ALL
 */
 DaysOfWeek.ALL = [
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
]

/**
 * Search for an range based on the value
 * 
 * @param {Number} Value 
 * 
 * @return {Object} day
 */
 DaysOfWeek.getByValue = (value) => {
  let day = DaysOfWeek.ALL.find(r => r.value === value)
  return day || null
}

export default DaysOfWeek
