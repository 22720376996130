/**
 * @property {Number = 0} APPLICATION_INIT 
 *  The application is initialized
 */
const APPLICATION_INIT = 0

/**
 * @property {Number = 1} APPLICATION_MIDDLEWARES
 *  The application is executing the middlewares
 */
const APPLICATION_MIDDLEWARES = 1

/**
 * @property {Number = 16} APPLICATION_READY
 *  The application is display some ui
 */
const APPLICATION_READY = 16

export { 
  APPLICATION_INIT,
  APPLICATION_MIDDLEWARES,
  APPLICATION_READY
}
