<template>
  <div class="layout-modal-steps__item">
    <!-- Header -->
    <header class="layout-modal-steps__header popins-modal__header">
      <div class="layout-modal__intro">
        <!-- Caption -->
        <div 
          v-if="current >= 0 && total >= 1" 
          class="layout-modal-steps__caption">{{ $t('partner.modal_step_counter', {current: current+1, total: total }) }}</div>

        <div class="row">
          <!-- Icon -->
          <ui-icon 
            v-if="icon" 
            class="layout-modal-steps__icon" 
            :glyph="icon"
            />

          <!-- Title -->
          <div class="layout-modal__heading">
            <h1 class="layout-modal__title">{{ title }}</h1>
            <p 
              class="layout-modal__description" 
              v-if="description"
              >{{ description }}</p>
          </div>
        </div>
      </div>

      <!-- Search -->
      <forms-search 
        v-if="searchable"
        class="layout-modal__search"
        @search="onSearch" />

      <slot name="header"></slot>
    </header>

    <!-- Body -->
    <div class="layout-modal-steps__body popins-modal__body">
      <slot></slot>
    </div>

    <!-- Footer -->
    <footer class="layout-modal-steps__footer popins-modal__footer">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'LayoutModalStepItem',

  props: {
    aggregate: {
      type: Object
    },

    close: {
      type: Function
    },

    current: {
      type: Number
    },

    description: {
      type: String,
      default: null,
    },

    hasNext: {
      type: Boolean
    },

    hasPrevious: {
      type: Boolean
    },

    icon: {
      type: String
    },

    loading: {
      type: Boolean,
      default: false
    },

    next: {
      type: Function
    },

    placeholder: {
      type: String,
      default: null
    },

    previous: {
      type: Function
    },

    searchable: {
      type: Boolean,
      default: false
    },
    
    title: {
      type: String
    },

    total: {
      type: Number
    },

    visible: {
      type: Boolean,
    }
  },

  methods: {
    onSearch(value) {
      this.$emit('search', value);
    }
  }
}
</script>
