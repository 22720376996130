<template>
  <nav class="n-breadcrumb">
    <template v-for="(c, i) in children">
      <button 
        :key="$basil.uniqId('breadcrumb')"
        v-bind="getAttrsForItem(c)"
        v-on="getListenersForItem(c)">

        <!-- Icon -->
        <ui-icon 
          v-if="c.icon"
          class="n-breadcrumb__icon" 
          :glyph="c.icon"
          />

        <!-- Image -->
        <img 
          v-if="c.image"
          class="n-breadcrumb__image" 
          :src="c.image"
          />

        <!-- Label -->
        <span 
          v-if="c.label"
          class="n-breadcrumb__label">{{ c.label }}</span>
      </button>

      <span 
        v-if="i != nChildren"
        :key="$basil.uniqId('breadcrumb')"
        class="n-breadcrumb__separator"></span>
    </template>
  </nav>
</template>

<script>
export default {
  name: 'NavBreadcrumb',

  props: {
    children: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    nChildren(){
      return this.children.length - 1
    }
  },

  methods: {
    getAttrsForItem(item){
      let href = this.$basil.get(item, 'href')
      let type = this.getTypeForItem(item)

      let ret = {
        class: 'n-breadcrumb__item',
        is: type,
      }

      if (type === 'router-link'){
        ret.to = href
      }

      return Object.assign({}, ret, item)
    },  

    getListenersForItem(item){
      let ret = {}
      let type = this.getTypeForItem(item)

      if(type === 'button'){ 
        ret = item.listeners || {}
      }

      return ret
    },

    getTypeForItem(item){
      let href = this.$basil.get(item, 'href')
      return this.$basil.isNil(href) === false ? 'router-link' : 'button'
    }
  }
}
</script>
