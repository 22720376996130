import { config } from '@/env'
import { GingerModuleConfig } from '@spices/ginger'
import { basil } from '@spices/basil'

/**
 * Boostrap the application
 *  With the client server (all envs)
 *  With the client filesystem (only development)
 * 
 * @param {Object} options
 * @param {GingerCapabilities} options.capabilities
 * @param {Ginger} options.$ginger
 * @returns {Promise.resolve}
 */
export default function ({ capabilities, $ginger }) {
  $console.group('sayl-bootstrap')

  return new Promise((resolve, reject) => {
    $dl.bootstrap()
      .then((data) => {
        $ginger.options = data
        resolve(data)
      })
      .catch((e) => {
        $console.error(e)
        reject(e)
      })
      .finally(() => $console.groupEnd('sayl-bootstrap'))
  })
}
