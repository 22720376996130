import VCalendar from 'v-calendar'

/**
 * Register V-Calendar
 * 
 * @param {Object} options
 * @param {GingerCapabilities} options.capabilities
 * @returns {Promise.resolve}
 */
export default function ({ capabilities }) {
  $console.group('utils-vcalendar')
  
  let Vue = capabilities.vue
  Vue.use(VCalendar)
  
  $console.groupEnd('utils-vcalendar')
  return Promise.resolve()
}
