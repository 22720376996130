import moment from 'moment'
import momentTz from 'moment-timezone'

/**
 * Register Moment.js
 * 
 * @param {Object} options
 * @param {GingerCapabilities} options.capabilities
 * @returns {Promise.resolve}
 */
export default function ({ capabilities }) {
  $console.group('utils-moment')

  let Vue = capabilities.vue

  Object.defineProperty(Vue.prototype, '$moment', {
    get() { return moment }
  })

  Object.defineProperty(Vue.prototype, '$momentTz', {
    get() { return momentTz }
  })

  $console.groupEnd('utils-moment')
  return Promise.resolve()
}
