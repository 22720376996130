<template>
  <layout-modal
    :closable="true"
    searchable
    :title="$t('partner.modal_impersonate_title')"
    :description="$t('partner.modal_impersonate_description', { name: $basil.get(value, 'companyname') })" 
    :visible="true"
    @close="onClose"
    @back="onClose"
    @discard="onClose"
    @search="onSearch"
    v-if="openModal">
    <template v-if="loading">
      <div class="layout-modal__loader">
        <ui-loader />
      </div>
    </template>
    
    <template v-else>
      <article class="flow" >
        <forms-list
          :events="events"
          :key="key"
          :loading="loading"
          :size="$pepper.Size.S"
          v-model="admins">
          <template 
            v-slot:entry="{entry}"
          >{{ $basil.get(entry, 'name') }} {{ $basil.get(entry, 'lastname') }}</template>
        </forms-list>
      </article>
    </template> 

    <template v-slot:footer>
      <actions-button
        :appearance="$pepper.Appearance.DEAFAULT"
        @click="onClose"
      >{{ $t('partner.close') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
export default {
  name:'ImpersonateModal',
  
  props: {
    value: {
      type: Object,
      required: true
    },

    openModal: {
      type: Boolean,
      required: true,
    },

    onClose: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      searching:false,
      searchQuery:'',
      key: 1,
    }
  },

  computed:{
    events() {
      return {
        click: (row) => this.impersonate(this.$basil.get(row, 'id'))
      }
    }, 

    admins() {
      let ret = this.$basil.get(this.value, 'admins', []) || []

      if(this.$basil.isNil(this.searchQuery) || this.$basil.isEmpty(this.searchQuery)) {
        return ret
      }

      return ret.filter((admin) => {
        return this.searchQuery
          .toLowerCase()
          .split(" ")
          .every(v => {
            return admin = admin.name.toLowerCase().includes(v) || admin.lastname.toLowerCase().includes(v)
          }) 
      })
    },
  },

  methods: {
    impersonate(adminId) {
      window.$dl.host.startImpersonation({ admin_id: adminId })
        .then((response) => window.open(response.data.redirect, "_blank"))
        .catch(e => $console.error(e))
    },

    onSearch(value) {
      this.searchQuery = value
    },
  },
}
</script>
