import errorsRoutes from './errors'
import SaylPartnerHome from '@/views/pages'
import SaylPartnerClients from '@/views/pages/clients'

let root = 'sayl-partner'

const routes = [
  // {
  //   name: `${root}_home`,
  //   path: '',
  //   component: SaylPartnerHome,
  //   meta: {
  //     bodyClass: 'sayl-partner-home'
  //   }
  // },
  {
    name: `${root}_home`,
    path: '',
    component: SaylPartnerClients,
    meta: {
      bodyClass: 'sayl-partner-clients'
    }
  }
]

export default routes.concat(errorsRoutes)
