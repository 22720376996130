export default {
  namespaced: true,

  mutations: {
    setEdition: (state, edition) => state.edition = edition,
    setOrigin: (state, origin) => state.origin = origin,
    setChildren: (state, children) => state.children = children,
    setPartners: (state, values) => state.partners.all = values,
    setLoading: (state, value) => state.partners.loading = value,
  },

  state: {
    edition: null,
    origin: null,
    children: null,
    partners: {
      all: null,
      invalid: true,
      loading: true,
      search: { value: '' },
      pagination: { currentPage: 1 },
    },  
  },
}
