<template>
  <div 
    class="filter"
    :ref="`filter${index}`"
    @click="onClick"
    v-if="filter.type !== 'button' && filter.component == null">
    <data-badge
      class="filter__count"
      :value="$basil.get(filter, 'values.length', 0)"
      v-if="filter.multiple && $basil.get(filter, 'values.length', 0) > 0"
    />
    
    <span class="filter__label">{{ $basil.get(filter, 'label') }}</span>

    <div
      class="filter__icon" 
      :class="getIconClass(filter)">
      <ui-icon 
        glyph="angle-down" 
      />
    </div>

    <popins-list-dropdown
      :options="options"
      :multiple="$basil.get(filter, 'multiple', false)"
      style="z-index: 2999;"
      position="bottom-left"
      :target="$refs[`filter${index}`]"
      :visible="showPopin"
      @change="onChange"
      v-click-outside="onClickOutside"
      v-if="filter.type !== Date"
    />

    <popins-popin 
      position="bottom-left"
      style="z-index: 2999;"
      :target="$refs[`filter${index}`]"
      :visible="showPopin"
      v-click-outside="onClickOutside"
      v-if="filter.type === Date">
        <!-- :max-date="filter.maxDate || null" -->
      <forms-calendar 
        :min-date="filter.minDate || null"
        :ranges="filter.ranges"
        :value="filter.values"
        @apply="onApply" 
        @cancel="onClick"
        @input="filter.events.input"
      />
    </popins-popin>
  </div>

  <actions-button
    class="filter"
    size="s"
    v-bind="filter"
    v-else-if="filter.type === 'button' && filter.component == null"
    v-on="filter.events"
  ><span class="filter__label">{{ $basil.get(filter, 'label') }}</span></actions-button>

  <component 
    class="filter"
    :is="filter.component"
    v-bind="filter"
    v-on="filter.events"
    v-else-if="filter.component != null"
  ><span class="filter__label">{{ $basil.get(filter, 'label') }}</span></component>
</template>

<script>
import { ClickOutside } from '@pepper/pepper'

export default {
  name: 'UiFilterItem',
  directives: {
    ClickOutside
  },
  props: {
    filter: {},
    showPopin: {
      type: Boolean,
      default: false,
    },
    index: {}
  },

  data() {
    return {
      activateClickOutside: false
    }
  },

  computed: {
    count() {
      return this.$basil.get(this.filter, 'values.length');
    },

    options(){ 
      let opt = this.$basil.get(this.filter, 'options', []);
      return opt.map(o => {
        o.active = this.filter.values.find(v => o.value === v.value) != null
        return o
      })
    },
  },

  watch: {
    showPopin: {
      immediate: true,
      handler() {
        if(this.showPopin) {
          setTimeout(() => {
            this.activateClickOutside = true
          }, 100);
        } else {
          this.activateClickOutside = false
        }
      }
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },

    onClickOutside() {
      return this.activateClickOutside ? this.onClick() : () => {}
    },

    onChange(values) {
      let ret = values.filter(v => v.active)
      
      if(!this.$basil.get(this.filter, 'multiple', false)) {
        ret = ret.length > 0 ? ret[0] : null
      } 
      
      this.$emit('change', ret)
    },

    onApply() {
      this.$basil.get(this.filter, 'events.apply', () => {})()
      this.onClick()
    }, 

    getIconClass() {
      return this.showPopin ? 
        '-active' : ''
    },
  },
}
</script>
