import VueI18n from 'vue-i18n'

/**
 * @class
 */
export default class i18nController {

  /**
   * @constructor
   * @param {Object} options 
   * @param {Array} options.transports 
   * @param {Vue} options.Vue 
   */
  constructor({ basil, eventbus, store, transports, Vue }){
    this.$basil = basil
    this.$eventbus = eventbus
    this._locale = Vue.observable(this.$basil.i18n.locale)
    this._locales = Vue.observable(this.$basil.i18n.locales)

    this.transports = transports;
    this.config = {};
    this.messages = {};
    this.messages[this.$basil.i18n.locale.langtag] = {};

    Vue.use(VueI18n);
    this.i18n = new VueI18n({
      locale: this.$basil.i18n.locale.langtag,
      fallbackLocale: this.$basil.i18n.locale.langtag,
      messages: this.messages,
    });

    // Object.defineProperty(Vue.prototype, '$date', {
    //   get: () => this.formatDate.bind(this)
    // })
    Object.defineProperty(Vue.prototype, '$locale', {
      get: () => this._locale,
      set: (value) => this.setLocale(value)
    })
    Object.defineProperty(Vue.prototype, '$locales', {
      get: () => this._locales
    })
  }

  /**
   * @property {VueI18n} i18nPlugin The VueI18n plugin instance
   */
  get plugin(){
    return this.i18n
  }

  ///////////////////////////////////////////////////////////////

  /**
  * Set the configuration
  * 
  * @param {Object} value 
  */
  setConfig(value) {
    this.config = Object.assign(this.config, value);
    $console.info('i18n.setConfig', this.config);
    $console.info('i18n.baseConfig', this.i18n);
    $console.debug('-value', value);

    let lang = value.languages.find(f => this.$basil.i18n.locale.langtag === f)
    if(this.$basil.isNil(lang)) {
      let fallback_language = value.fallback_language.split('_').join('-')
      this.$basil.i18n.locale = fallback_language || 'en-US'
      this.i18n.locale = this.$basil.i18n.locale.langtag
    }

    // DateTime
    // if (value.datetime) {
    //   value.datetime.forEach(({ iso, ...formats }) => {
    //     this.i18n.setDateTimeFormat(new Locale(iso).toString(), formats);
    //   });
    // }

    // Number
    // if (value.number) {
    //   value.number.forEach(({ iso, ...formats }) => {
    //     this.i18n.setNumberFormat(new Locale(iso).toString(), formats);
    //   })
    // }

    // fallback
    if (value.fallback_language) {
      let fallback_language = value.fallback_language.split('_').join('-')
      let fallback = new this.$basil.i18n.Locale(fallback_language)
      
      this.$basil.i18n.fallback = fallback
      this.i18n.fallbackLocale = fallback.toString()
    }

    // languages
    if (value.languages){
      this.$basil.i18n.locales = value.languages.map(l => new this.$basil.i18n.Locale(l));
      // this.i18n.availableLocales = value.languages;
    }

    // messages
    if (value.messages) {
      value.messages.forEach(({ iso, messages: m }) => {
        let v = new this.$basil.i18n.Locale(iso);
        let l = v.langtag

        if (!this.messages.hasOwnProperty(l)) {
          this.messages[l] = {};
        }

        let entry = Object.assign(this.messages[l], m);
        this.i18n.setLocaleMessage(l, entry);
      });
    }

    // Number
    // if (value.number) {  
    //   value.number.forEach(({ iso, currency }) => {
    //     this.i18n.mergeNumberFormat(iso, { currency })
    //   })
    //   let cur = this.i18n.getNumberFormat(this.i18n.locale)
    //   this.$basil.i18n.defaultCurrency = cur && cur.currency && cur.currency.currency ? cur.currency.currency : 'EUR'
    // }
    // this.setLocale(this.locale.toString());
  }

  /**
   * Set the current locale
   * Will first validate the given value before applying it for the application
   * 
   * @param {String} value 
   */
  setLocale(value) {

    // // Not valid
    // if (!l.valid) {
    //   l = this.locales.find(nl => nl.lang.toString() === value);
    //   if (!l) {
    //     throw new Error(`[i18n] Unknow locale ${value}`);
    //   }
    // }

    // // Not in the allowed set
    // let locale = this.locales.find(nl => nl.iso === l.iso);
    // if (!locale) {
    //   console.warn(`[i18n] Unauthorized locale ${value}`);
    //   console.info(`Allowed values: ${this.locales.map(l => l.toString())}`)
    //   return;
    // }

    // // Same as before
    // if (this.locale && locale.iso === this.locale.iso) {
    //   return;
    // }

    // Not ready yet
    if (!this.i18n) {
      return;
    }

    let l = new this.$basil.i18n.Locale(value)
    let changed = l.toString() !== this.$basil.i18n.locale.toString()
    $console.debug('$dl.i18n.setLocale', l)

    this.$basil.i18n.locale = l
    this.i18n.locale = l.toString()

    // this.$basil.i18n.defaultCurrency = this.i18n.getNumberFormat(this.i18n.locale).currency.currency

    // Transports update
    let k = Object.keys(this.transports)
    k.forEach(t => {
      t = this.transports[t]
      if (t.hasOwnProperty('defaults')) {
        t.defaults.headers.common['Accept-Language'] = l.iso
      }
    })

    // Header update
    document.querySelector('html').setAttribute('lang', l.toString())

    // Emit change
    if (changed){
      this.$eventbus.$emit('i18n.locale.updated')
    }
  }

  watcher(){
    console.log()
  }  
}
