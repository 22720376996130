import { basil } from '@spices/basil'

import { 
  ACLController, 
  CPController,
  HostController,
  // CurryController, 
  i18nController, 
  OAuthController, 
  PlansController, 
  transports, 
  http 
} from './controllers'


import DateRanges from './models/ranges'
import Formats from './models/export-formats'
import DaysOfWeek from './models/days-of-week'
import Types from './models/types'
import Blockchains from './models/blockchains'

/**
 * Sayl Data Layer
 * 
 * @class
 */
class DataLayer {

  /**
   * @constructor
   */
  constructor({ basil, eventbus, store, Vue }){
    this._store = store

    this._acl = new ACLController({ store, Vue })
    this._cp = new CPController({ store, Vue })
    this._host = new HostController({ store, transports }) 
    this._i18n = new i18nController({ basil, eventbus, store, transports, Vue })
    this._oauth = new OAuthController({ store, Vue })
    this._plans = null
  }

  /////////////////////////////////////////////////////////////////////
  /**
   * Return the list of the available blockchains to work within the 
   * SAYL platform
   * 
   * @property {Object} Blockchains
   * @readonly
   * @returns {Object}
   */
  get blockchains() {
    return Blockchains
  }

  /**
   * @property {Object} bootstrapData The bootstrap data
   */
  get bootstrapData(){
    return this._cp.data
  }

  /**
   * @property {VueI18n} i18nPlugin The VueI18n plugin instance
   */
  get i18nPlugin(){
    return this._i18n.plugin
  }

  /**
   * @property {i18nController} i18n The i18n controller instance
   */
  get i18n(){
    return this._i18n
  }

  get ranges() {
    return DateRanges
  }

  get formats() {
    return Formats
  }

  get daysOfWeek() {
    return DaysOfWeek
  }

  get types() {
    return Types
  }

  get host() {
    return this._host
  }

  /**
   * @property {Axios} $http The http axios connector 
   */
  get $http(){
    return http
  }

  /////////////////////////////////////////////////////////////////////

  /**
   * Triggers the user authentication process
   */
  authenticate(){
    $console.info('dl.authenticate')

    this._oauth.authenticate()
      .catch(e => {
        $console.error('dl.authenticate', e)
      }) 
  }

  /**
   * Bootstrap the application
   * 
   * @returns {Promise}
   */
  bootstrap(){
    return new Promise((resolve, reject) => {
      $console.group('dl.bootstrap')
      
      this._cp.bootstrap()
      .then((data) => {
        // i18n config
        this._i18n.setConfig(data.i18n)

        // ACL
        if (!basil.isNil(data.user)) {
          if (!data.user.metadata || !data.user.metadata.front_role) {
            console.warn('User has no role defined.');
          }

          // @note this is temporary while all the users might not have the role set in the db.
          basil.sayl.role = basil.get(data, 'user.metadata.front_role', 'admin');
        }

        resolve(data)
      })
      .catch(e => {
        $console.error('dl.bootstrap', e)

        if (e && e.response && e.response.status != null && e.response.status === 401){
          this.authenticate()
        }
        return reject(e)
      })
      .finally(() => $console.groupEnd('dl.bootstrap'))
    })
  }

  /**
   * Log the user out of the application
   * 
   * @returns {Promise}
   */
  logout(){
    return this._oauth.logout()
  }
}

export default DataLayer
