<template>
  <forms-input
    :autofocus="autofocus"
    class="forms-search"
    clearable
    icon-pre="search"
    @input="(value) => $emit('search', value)"
    :placeholder="placeholder"
    :size="$pepper.Size.S"
    v-model="iValue">
    <slot></slot>
  </forms-input>
</template>


<script>
export default {
  name: 'FormsSearch',

  model: {
    event: 'input',
    prop: 'value'
  },

  data(){
    return {
      iValue: null
    }
  },

  watch:{
    value: {
      handler(val){
        this.iValue = val
      },
      immediate: true
    }
  },

  props: {
    autofocus: {
      type: Boolean,
      default: true
    },

    placeholder: {
      type: String,
      default: 'Start typing to filter out the values'
    },

    value: {
      type: String,
    }
  }
}
</script>
