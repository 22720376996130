import NotFound from '@/views/ui/errors/notfound'
import NotAuthenticated from '@/views/ui/errors/not-authenticated'
// import ItemNotFound from '@/views/ui/errors/item-notfound'
// import ServerError from '@/views/ui/errors/server'
import NotAllowed from '@/views/ui/errors/notallowed'
// import NavigationDemo from '../views/navigation-demo'

const routes = [
  {
    path: '*',
    name: '401',
    component: NotAuthenticated,
    meta: {
      bodyClass: 'notfound'
    }
  },

  {
    path: '*',
    name: 'notfound',
    component: NotFound,
    meta: {
      bodyClass: 'notfound'
    }
  },
  // {
  //   path: '/nav',
  //   name: 'nav',
  //   component: NavigationDemo,
  //   // component: NotFound,
  //   meta: {
  //     bodyClass: 'navdemo'
  //   }
  // },

  // {
  //   path: '*',
  //   name: 'item-notfound',
  //   component: ItemNotFound,
  //   meta: {
  //     bodyClass: 'notfound'
  //   }
  // },

  // {
  //   path: '*',
  //   name: 'server-error',
  //   component: ServerError,
  //   meta: {
  //     bodyClass: 'notfound'
  //   }
  // }
]

export default routes
