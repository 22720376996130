import cp from './cp'
import http from './http'
import oauth from './oauth'

const transports = { cp, http };
transports.getByName = (name) => {
  return transports[name];
}

export { 
  cp, 
  http,
  oauth,
  transports
}
