<template>
  <footer class="n-footer">
    <div class="container">
      <div class="n-footer__copy">
        <!-- Sayl Logo -->
        <img 
          class="n-footer__logo"
          :src="$getImageSrc('/statics/images/logos/sayl/color.svg')" 
          alt="Sayl - Color">

        <!-- Sayl Label -->
        <div class="n-footer__label">{{ $t('partner.copyright') }}</div>
      </div>

      <!-- Languages -->
      <forms-select 
        :appearance="$pepper.Appearance.SUBTLE"
        @change="changeLang" 
        class="n-footer__languages"
        :key="key"
        :options="langs"
        :size="$pepper.Size.S"
        v-model="locale"
        />

    </div>
  </footer>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'NavigationsFooter',

  data(){
    return {
      key: 0,
      locale: null,
      locales: null
    }
  },

  computed: {
    langs(){
      return (this.locales || []).map(l => {
        return {
          label: this.$t(`partner.lang_${l.lang.toString()}`),
          value: l.toString()
        }
      })
    },
  },

  methods: {
    changeLang(l) {
      $dl.i18n.setLocale(l)
    },
  },

  mounted(){
    this.locales = this.$basil.i18n.locales
    this.locale = this.$locale.toString()
    this.key++
  }
}
</script>
