import { CurryCountryDatalayer } from '@curry/country'

/**
 * Country controller
 * for @sayl 
 * @class
 */
 export default class CountryController {
  /**
   * @constructor
   * @param {Object} options
   * @param {String} options.transports - Name of the related transports
   * @param {Object} options.store
   */
  constructor({ transports, store }) {
    let dl = new CurryCountryDatalayer({ transports })
    this._curry = dl.controllers.find(c => c.fqn === 'ifm.country')
    this._store = store
    this._loading = false
  }

  ////////////////////////////////////////
  /**
   * Fetches all the images for an entity
   * 
   * @param {Object} options 
   * @param {Object} options.data 
   * @param {Object} options.data.entityId 
   * @param {Object} options.data.entityType
   * @param {Object} options.data.fieldName
   * @param {Object} options.entity
   * @param {Object} options.module
   * 
   * @return {Promise}
   */
  all({}) {
    if(!this.loading) {
      return new Promise((resolve, reject) => {
        this.loading = true
        let countries = basil.get(this._store, 'state.sayl.countries', null)
        
        if(!basil.isNil(countries)) {
          this.loading = false
          resolve(countries)
        } else {
          this._curry.all({})
            .then((collection) => {
              collection = collection
                .sort((a, b) => a.name - b.name)
              
              this._store.commit(`sayl/setCountries`, collection)
              resolve(collection)
            })
            .catch((e) => reject(e))
            .finally(() => this.loading = false)
        }
      })
    } else {
      return Promise.reject(new Error('Call already done'))
    }
  }
}
