const json = {
  "sayl": [
    { "fqn": "sayl-partner-home" },
    { "fqn": "sayl-partner-clients" },
    // { "fqn": "sayl-customer" },
    // { "fqn": "sayl-integrations" },
    // {
    //   "fqn": "sayl-configure",
    //   "children": [
    //     { "fqn": "sayl-cms" },
    //     { "fqn": "sayl-configure-users" },
    //     { "fqn": "sayl-configure-project" },
    //     { "fqn": "sayl-configure-tags" },
    //     { "fqn": "sayl-configure-vats" },
    //     { "fqn": "sayl-configure-webhooks" },
    //     { "fqn": "sayl-configure-public-api-tokens" }
    //   ]
    // }
  ],
  // "sayl-resto": [
  //   {
  //     "fqn": "sayl-resto-orders-home",
  //     "children": [
  //       { "fqn": "sayl-resto-orders" },
  //       { "fqn": "sayl-resto-feedbacks" }
  //     ]
  //   },
  //   {
  //     "fqn": "sayl-resto-commerce",
  //     "children": [
  //       { "fqn": "sayl-resto-commerce-catalogs" },
  //       { "fqn": "sayl-resto-commerce-products" },
  //       { "fqn": "sayl-resto-commerce-options" },
  //       { "fqn": "sayl-resto-commerce-inventory" },
  //       { "fqn": "sayl-resto-commerce-attributes" },
  //       { "fqn": "sayl-resto-commerce-vouchers" },
  //       { "fqn": "sayl-resto-commerce-displayrules" },
  //       { "fqn": "sayl-resto-commerce-productassociations" }
  //     ]
  //   },
  //   {
  //     "fqn": "sayl-resto-configure",
  //     "children": [
  //       { "fqn": "sayl-resto-overview" },
  //       { "fqn": "sayl-resto-shop" },
  //       { "fqn": "sayl-resto-preparationlocations" },
  //       { "fqn": "sayl-resto-layout" },
  //       { "fqn": "sayl-resto-seats" },
  //       { "fqn": "sayl-resto-embed" }
  //     ]
  //   }
  // ],
  // "sayl-customer": [
  //   {
  //     "fqn": "sayl-customer-identity",
  //     "children": [
  //       { "fqn": "sayl-customer-segments" },
  //       // { "fqn": "sayl-customer-behaviors" },
  //       { "fqn": "sayl-customer-campaigns" }
  //     ]
  //   },
  //   { "fqn": "sayl-customer-activate" },
  //   { "fqn": "sayl-customer-bookings" },
  //   { "fqn": "sayl-customer-loyalty" }
  // ],
  // "sayl-conn3ct": [
  //   { 
  //     "fqn": "sayl-conn3ct-nft-module",
  //     "children": [
  //       { "fqn": "sayl-conn3ct-nft", },
  //       { "fqn": "sayl-conn3ct-nft-collections", }
  //     ]
  //   },
  //   { "fqn": "sayl-conn3ct-web3-triggers" },
  //   { "fqn": "sayl-conn3ct-settings-enablers" },
  //   // { "fqn": "sayl-conn3ct-settings-wallet" },
  //   { "fqn": "sayl-conn3ct-settings-shop" },
  //   { "fqn": "sayl-conn3ct-orders" },
  // ]
}

export default json
