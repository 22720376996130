<template>
  <div class="data-table-cell-boolean data-table-cell-group group">
    <!-- <div 
      :class="product.class" -->
      <ui-icon
        :class="classes"
        :glyph="icon"
        :title="title"
      />
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: 'DataTableCellBoolean',

  props: {
    value: {
      type: Boolean, 
      required: true,
    },

    title: {
      type: String
    }
  },

  computed: {
    classes() {
      return {
        'data-table-cell-boolean__icon': true,
        '-is-active': this.value === true
      }
    },

    icon() {
      return this.value === true ? 'check' : 'cross'
    }
  },
}
</script>
