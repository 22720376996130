<template>
  <popins-modal
    :closable="closable"
    :klass="classes"
    :visible="visible"
    position="top-center"
    v-on="$listeners"
    @close="close">
    <!-- Header -->
    <template v-slot:header>
      <div class="layout-modal__intro">
        <div class="row">
          <!-- Icon -->
          <ui-icon 
            v-if="icon" 
            class="layout-modal__icon" 
            :glyph="icon"
            />

          <!-- Title -->
          <div class="layout-modal__heading">
            <h1 class="layout-modal__title">{{ title }}</h1>
            <p 
              class="layout-modal__description" 
              v-if="description"
            >{{ description }}</p>
          </div>
        </div>
      </div>

      <!-- Search -->
      <forms-search 
        v-if="searchable"
        class="layout-modal__search"
        @search="onSearch" />

      <!-- Slot::Header -->
      <slot name="header"></slot>
    </template>

    <div class="layout-modal__body">
      <slot v-bind="bodyAttrs"></slot>
    </div>

    <template v-slot:footer>
      <slot 
        name="footer" 
        v-bind="bodyAttrs"></slot>
    </template>
  </popins-modal>
</template>


<script>
export default {
  name: "LayoutModal",

  props: {
    closable: {
      type: Boolean,
      default: false
    },

    description: {
      type: String,
      default: null,
    },

    icon: {
      type: String
    },

    title: {
      type: String
    },

    visible: {
      type: Boolean,
      default: false
    },

    searchable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    bodyAttrs() {
      return {
        close: this.close
      };
    },

    classes(){
      let ret = {
        'layout-modal': true
      }

      return {
        ...ret,
        ...this.$attrs.classes
      }
    },
  },

  methods: {
    close() {
      // this.$emit("close");
    },

    onSearch(value) {
      this.$emit('search', value);
    }
  },
};
</script>
