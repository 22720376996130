<template>
  <div :class="classes">
    <header 
      v-if="hasHeader" 
      class="ui-card__header">
      <slot 
        name="header"
        :title="title"
        :icon="icon">
        <div
          v-if="icon" 
          class="ui-card__figure">
          <div class="ui-card__icon">
            <ui-icon :glyph="icon" />
          </div>
        </div>
        <div class="ui-card__title">{{ title }}</div>
      </slot>

      <slot name="subtitle"></slot>
    </header>

    <div class="ui-card__content">

      <div class="ui-card__body">
        <slot></slot>
      </div>

      <footer
        v-if="hasFooter"
        class="ui-card__footer">
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiCard',

  props: {
    appearance: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'subtle'].includes(value)
    },

    article: {
      type: Boolean,
      default: false
    },

    autoPadding: {
      type: Boolean,
      default: true,
    },

    theme: {
      type: String,
      default: null
    },

    icon: {
      type: String
    },

    title: {
      type: String
    },
  },

  computed:{
    classes(){
      return {
        'ui-card': true,
        '-no-header': !this.hasHeader,
        '-no-footer': !this.hasFooter,

        '-article': this.article === true,

        '-is-default': this.appearance === 'default',
        '-is-subtle': this.appearance === 'subtle',

        '-no-padding': !this.autoPadding,

        '-sayl-customer': this.theme === 'customer',
        '-sayl-conn3ct': this.theme === 'conn3ct',
      }
    },

    hasBody(){
      return this.hasSlot('default');
    },
    
    hasFooter(){
      return this.hasSlot('footer');
    },

    hasHeader(){
      return this.hasSlot('header') === true || (this.title != '' && this.title != undefined);
    },

  },

  methods: {
    hasSlot(name){
      return !!this.$slots[name];
    }
  }
}
</script>
