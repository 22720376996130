<template>
  <popins-modal 
    :klass="classes"
    :closable="true"
    :visible="visible"
    position="top-right"
    :size="size"
    @back="$emit('back')"
    @close="$emit('close')">

    <!-- Header -->
    <template v-slot:header>
      <!-- Header -->
      <nav-header v-bind="header">

        <template slot="extra">
          <slot name="header"></slot>
        </template>  

        <!-- Filters -->
        <template v-slot:filters v-if="$root.canViewPage">
          <slot name="filters"></slot>
        </template>

      </nav-header>
    </template>


    <div class="layout-level2__body">
      <ui-errors 
        :errors="errors"
        @back="$emit('back')">
        <!-- Body -->
          <!-- Inheritance parent -> [child] -->
          <notice-banner 
            v-if="inheritedFromParent"
            class="layout-level2__banner"
            :intent="$pepper.Intent.WARNING">
            <template v-slot:title>{{ $t('partner.inheritance_parent_title') }}</template>
            <p>{{ $t('partner.inheritance_parent_description') }}</p>
          </notice-banner>

          <!-- Inheritance [parent] -> child -->
          <notice-banner
            v-if="inheritedFromChild"
            class="layout-level2__banner">
            <template v-slot:title>{{ $t('partner.inheritance_child_title') }}</template>
            <p>{{ $t('partner.inheritance_child_description') }}</p>
          </notice-banner>
          
          <!-- Loading -->
          <div 
            v-if="loading" 
            class="layout-level2__loader">
            <ui-loader />
          </div>

          <!-- Data -->
          <slot v-else></slot>

          <forms-save 
            v-if="!pristine"
            @save="save"
            />
      </ui-errors>
    </div>
  </popins-modal>
</template>

<script>
import { 
  MixinHeader, 
  MixinInheritance,
  MixinError 
} from '@sayl/admin-common'

import NavHeader from '../components/navigations/header'

export default {
  name: "LayoutLevel2Sided",

  components: {
    NavHeader
  },

  mixins: [
    MixinHeader,
    MixinInheritance,
    MixinError
  ],
  
  props: {
    errors: {
      type: Object,
      default:() => { 
        return {} 
      }
    },

    size: {
      type: String,
      default: 'l'
    },

    visible: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    classes(){
      return Object.assign({
        'layout-level2': true,
        'layout-level2-sided': true
      }, this.$attrs.classes);
    },
  }
}
</script>
