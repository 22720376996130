import { basil } from '@spices/basil'
import { ACLRoles, ACLResources, ACLRules } from '../models'

export default class ACLController{
  constructor({ store }){
    store.commit('sayl/setRoles', ACLRoles);
    store.commit('sayl/setResources', ACLResources);

    ACLRules.forEach(r => {
      if (r.resources) {
        r.resources.forEach(rs => {
          basil.sayl.addRule({ access: r.access, role: r.role, resource: rs, privileges: r.privileges })
        })
      }
      else {
        basil.sayl.addRule(r)
      }
    })
  }
}
