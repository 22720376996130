import Axios from 'axios'
import oauth from '../oauth'

const cp = Axios.create({
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
});

const response = (response) => response
const error = (error) => {
  if ([401, 403].includes(error.response.status)) {
    return oauth.authenticate()
    // document.location.href = "/"
  }

  return Promise.reject(error)
}

// cp.interceptors.response.use(response, error);

export default cp
