<template>
  <layout-level1 v-bind="attrs">
    <template #filters>
      <ui-filters
        has-reset
        :actions="actions"
        :filters="filters"
        @reset="reset"
      />
    </template>

    <div class="layout-level1__container container flow">
    
      <forms-input 
        :placeholder="$t('partner.search_placeholder')"
        type="search"
        icon-post="cross"
        icon-post-interactive
        @iconClick="() => this.search.value = ''"
        v-model="search.value"
      />
      <data-empty
        :title="$t('partner.clients_projects_empty_title')"
        :description="$t('partner.clients_projects__empty_description')"
        v-if="projectsList.length === 0"
      />

      <div
        :key="$basil.uniqId(project.name)"
        v-for="project in projectsList">
        
        <h3 class="view-clients__title">{{ $basil.get(project, 'name') }}</h3>

        <data-table
          :actions="actionsTable"
          :columns="columns"
          :loading="loading"
          ref="table"
          :rows="rows(project)"
          v-model="tSelection"
          @page="page"
        />

      </div>
    </div>

    <create-modal
      :openModal="openModalCreation"
      :value="newOne"
      :errors="errors"
      :loading="creating"
      :onClose="onCloseCreation"
      @submit="onSave"
    />

    <impersonate-modal
      :openModal="openModal"
      :value="currentRow"
      :onClose="onClose"
    />
  </layout-level1>
</template> 

<script>
import { mapGetters, mapState } from 'vuex'

import { MixinError, MixinTable } from '@sayl/admin-common'

import CreateModal from './create.vue'
import DataTableCellBoolean from './components/table-cell-boolean'
import DataTableCellProducts from './components/table-cell-products'
import ImpersonateModal from './impersonate.vue'

export default {
  name: 'PartnerPageClients',

  components: {
    CreateModal,
    DataTableCellBoolean,
    DataTableCellProducts,
    ImpersonateModal,
  },

  mixins: [
    MixinError,
    MixinTable,
  ],

  data() {
    return {
      currentRow: {},
      errors: {},
      newOne: {
        name: '',
        email: '',
        sender: ''
      },
      openModal: false,
      openModalCreation: false,
      creating: false,
      selectedProducts: [],
      selectedProject: [],
      // search: ''  
    }
  },

  computed: {
    ...mapState({
      state: state => state.host.partners,
      projects: state => state.host.partners.all,
    }),

    ...mapGetters({
      config: 'sayl/config'
    }),

    actions() {
      return [
        {
          events: {
            click: () => this.onCreate(),
          },
          icon: 'plus',
          label: this.$t('partner.add'),
        }
      ]
    },

    actionsTable() {
      return [
        {
          label: this.$t('partner.client_action_impersonate'),
          mode: this.$pepper.Action.SINGLE,
          action: (row) => this.onCustomerClick(row)
        }
      ]
    },

    attrs() {
      return {
        breadcrumb: this.breadcrumb,
        class: 'view-clients',
        description: this.description,
        icon: this.icon,
        loading: this.loading,
        title: this.title,
      }
    },

    breadcrumb() {
      return [
        { label: this.title, icon: this.icon },
      ]
    },

    columns() {
      return [
        { fqn: 'name', label: this.$t('partner.clients_col_name') },
        // { fqn: 'products', label: this.$t('partner.clients_col_products') },
        { fqn: 'created', label: this.$t('partner.clients_col_create'), type: this.$pepper.DataType.DATETIME },
      ]
    },

    description() {
      return this.$t('partner.clients_description')
    },

    filters() {
      return [
        (this.projects && this.projects.length > 1) ? {
          label: this.$t('partner.clients_filters_projects'),
          options: this.projectsFilter,
          values: this.selectedProject,
          require: false,
          events: {
            change: (values) => {
              this.selectedProject = [values]
            },

            close: () => {
              this.selectedProject = []
            }
          },
        } : null,
        // {
        //   label: this.$t('partner.clients_filters_products'),
        //   options: this.products,
        //   values: this.selectedProducts,
        //   require: false,
        //   multiple: true,
        //   events: {
        //     change: (values) => {
        //       this.selectedProducts = values
        //     },

        //     close: (value) => {
        //       this.selectedProducts.splice(this.selectedProducts.indexOf(value), 1)
        //     }
        //   },
        // }
      ].filter(f => !this.$basil.isNil(f))
    },

    icon() {
      return 'users'
    },

    // products() {
    //   return [
    //     { label: this.$t('partner.product_sayl_resto'), value: 'sayl_resto' },
    //     { label: this.$t('partner.product_sayl_customer'), value: 'sayl_customer' },
    //     { label: this.$t('partner.product_sayl_conn3ct'), value: 'sayl_conn3ct' },
    //     { label: this.$t('partner.product_sayl_retail'), value: 'sayl_retail' },
    //   ]
    // },

    projectsList() {
      let ret = this.projects || []

      return this.selectedProject.length > 0 || this.selectedProducts.length > 0 ?
        ret.filter((r) => {
          let ret = []

          if(this.selectedProject.length > 0) {
            ret.push(this.$basil.get(this.selectedProject, `[0].value`, null) === r.id)
          }

          // if(this.selectedProducts.length > 0) {
          //   this.selectedProducts.forEach((p) => ret.push(!this.$basil.isNil(r.products.find((product) => product.slug.includes(p.value)))))
          // }

          return ret.filter(r => r === false).length === 0
        }) : ret
    },

    projectsFilter() {
      return this.projects?.map((project) => {
        return { label: project.name, value: project.id }
      })
    },

    title() {
      return this.$t('partner.clients_title')
    },
  },

  methods: {
    onClose() {
      this.openModal = false
    },

    onCloseCreation() {
      this.openModalCreation = false
    },

    onCreate() {
      this.openModalCreation = true
    },

    onSave(args) {
      this.creating = true
      window.$dl.host.save(args)
        .then(() => {
          this.newOne = {
            name: '',
            email: '',
            sender: ''
          }
          this.openModalCreation = false
          return window.$dl.host.all({})
        })
        .catch((error) => this.handleErrors(error))
        .finally(() => this.creating = false)
    },

    rows(project) {
      let ret = this.$basil.get(project, 'customers') || []
      let created = this.$basil.get(project, 'created')

      if(!this.$basil.isNil(created)) {
        created = created * 1000
      }

      // let products = this.$basil.get(project, 'products', [])
      return ret.map((customer, i) => {
        return {
          value: customer,
          data: [
            { 
              content: customer.companyname,
              id: customer.id
            },
            // {
            //   component: DataTableCellProducts, 
            //   values: products.map(p => p.slug) 
            // },
            { content: this.$basil.isNil(created) ? null : new Date(created) }
          ]
        }
      }).filter((r) => {
        if(!this.$basil.isNil(this.search.value) && !this.$basil.isEmpty(this.search.value)) {
          return this.search.value
            .toLowerCase()
            .split(" ")
            .every(v => r.value.companyname.toLowerCase().includes(v))
        }

        return true
      })
    },

    onCustomerClick(row) {
      this.currentRow = this.$basil.get(row, 'value', {})
      this.openModal = true
    },

    reset() {
      this.errors = {}
      return window.$dl.host.all({})
    }
  },

  mounted() {
    this.reset()
  }
}
</script>
