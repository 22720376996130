import Vue from 'vue'
import Vuex from 'vuex'
import sayl from './modules/sayl'
import host from './modules/host'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    sayl,
    host,
  },
  strict: false
})
