import { CurryHostDatalayer } from '@curry/host'

/**
 * Association controller
 * for @sayl/host
 * @class
 */
export default class HostController {
  /**
   * @constructor
   * @param {Object} options
   * @param {Object} options.curry - Curry Controller 
   * @param {Object} options.fqn  
   * @param {Object} options.module 
   * @param {Object} options.store
   */
  constructor({ store, transports }) {
    let c = new CurryHostDatalayer({ transports })
    this._curry = c.controllers.find((c) => c.fqn === 'ifm.partners')
    this._store = store
  }

  all() {
    return new Promise((resolve, reject) => {
      this._store.commit('host/setLoading', true)
      this._curry.all({})
        .then((d) => {
          this._store.commit('host/setPartners', d)
          resolve(d)
        })
        .catch((e) => reject(e))
        .finally(() => this._store.commit('host/setLoading', false))
    })
  }

  save(payload) {
    return this._curry.save({ item: payload })
  }

  startImpersonation(payload) {
    return this._curry.startImpersonation({ item: payload })
  }
}
