import { CurryImageModel } from '@curry/image'
import { PepperFile } from '@pepper/utils'

export default class SaylImageModel extends CurryImageModel {
  constructor(data) {
    super(data)

    let url = basil.get(data, 'url', null)
    if(url != null && url.all != null) {
      this._image = {
        all: new PepperFile({ 
          name: url.all.substr(url.all.lastIndexOf('/') + 1), 
          loaded: true, 
          loading: false, 
          src: url.all, 
          transferred: true 
        })
      }
    } else {
      this._image = {
        all: null
      }
    }
  }

  copy() {
    return new SaylImageModel(this.toObject())
  }

  toAPI() {
    let formData = new FormData();

    formData.append('image[all]', this.image.all.src)
    formData.append('alt[all]', '')
    formData.append('description[all]', '')
    formData.append('entity_type', this.entityType)
    formData.append('field_name', this.fieldName)
    formData.append('position', this.position)
    formData.append('title[all]', '')
    
    return formData
  }

  toObject() {
    let d = super.toAPI();
    d.image = this._image;

    return JSON.parse(JSON.stringify(d))
  }

  get image() {
    return this._image;
  }

  set image(value) {
    this._image = value;
  }
}
