import '@/styles/app.scss'
import Vue from 'vue'
import router from './router'
import store from './data/store'
import { config, isDevelopment, isStaging } from '@/env'

import { APPLICATION_MIDDLEWARES, DataLayer, transports } from './data'

// 
// Vue Configuration
// 
Vue.config.productionTip = false

// 
// Badge
// 
let c = isStaging ? 
        '#CDA22B' : // yellow 
        isDevelopment ? 
        '#D65F48' : // red
        '#2C9CCB' ; // blue

console.log(
  `%c @sayl/admin %c v${config.version.client} %c`,
  'background:#4E575D ; padding: 1px; border-radius: 3px 0 0 3px;  color: #FBF3EC',
  `background:${c} ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff`,
  'background:transparent'
);

// 
// Arguments
// 
import { Anise, Field } from '@spices/anise'
let args = Anise.detect([
  // debug
  new Field({
    name: 'debug',
    fallback: 'off',
    persistent: true,
    search: /debug=(all|debug|info|warn|error)/
  })
])

// 
// EventBus
// 
let eventbus = new Vue({})
Object.defineProperty(Vue.prototype, '$bus', {
  get() { return eventbus }
})

// 
// Logger
// 
import { Logger, Level } from '@spices/cayenne'
let $console = Logger.get()
$console.level = Level.getFromName(args.debug.toUpperCase())
Object.defineProperty(window, '$console', {
  get: () => $console
})

// 
// Basil
// 
import { basil as $basil, install as Basil } from '@spices/basil'
import { install as SaylInstall } from '@spices/basil-sayl'
import { install as basilI18n } from '@spices/basil-ic-i18n'
import { install as basilIc } from '@spices/basil-ic'

$basil.global = true
$basil.use(SaylInstall)
$basil.use(basilIc)
$basil.use(basilI18n, { locale: { persistent: true }})
Vue.use(Basil)

// 
// DataLayer
// 
let $dl = new DataLayer({ basil: $basil, eventbus, store, Vue })
Object.defineProperty(window, '$dl', {
  get: () => $dl
})

// 
// Ginger
// 
import { VueGinger } from '@spices/ginger'
import { default as GingerBodyPlugin } from '@spices/ginger-body'
import { middlewares } from './middlewares'
import GingerLoader from './views/ui/components/ui/ginger-loader'
store.commit('sayl/setState', APPLICATION_MIDDLEWARES)

Vue.use( VueGinger, {
  eventbus,
  loader: GingerLoader,
  middlewares,
  modules: [],
  logger: $console,
  options: config,
  plugins: [
    { options: { key: 'ABC' }, plugin: GingerBodyPlugin }
  ],
  router,
  store,
  transports,
});

import CountryController from './data/controllers/country'
import ImageController from './data/controllers/image'
import MixinFeature from './mixins/feature'

// 
//  Application
// 
import App from './app'

new Vue({
  provide: {
    '$image': new ImageController({ transports, store }),
    '$countries': new CountryController({ transports, store }),
  },
  mixins: [MixinFeature],
  router,
  store,
  i18n: $dl.i18nPlugin,
  render: h => h(App)
}).$mount('#sayl');
