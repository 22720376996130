/**
 * Register @spices/basil
 * 
 * @param {Object} options
 * @param {GingerCapabilities} options.capabilities
 * @returns {Promise.resolve}
 */
export default function ({ capabilities }) {
  $console.group('sayl-i18n')
  
  capabilities.eventbus.$on('i18n.locale.updated', () => {
    console.log('i18n.locale.updated')
  })
  
  $console.groupEnd('sayl-i18n')
  
  return Promise.resolve()
}
