import SaylBootstrap from './sayl-bootstrap'
import SaylComponents from './sayl-components'
import SaylI18n from './sayl-i18n'

import SpicesPepper from './spices-pepper'

import UtilsImage from './sayl-image'

import UtilsBugsnag from './utils-bugsnag'
import UtilsChart from './utils-chart'
import UtilsGmap from './utils-gmap'
import UtilsMoment from './utils-moment'
import UtilsVCalendar from './utils-vcalendar'

export const middlewares = [
  SpicesPepper,
  UtilsImage,
  
  UtilsBugsnag,
  UtilsChart,
  UtilsMoment,
  UtilsVCalendar,
  
  SaylBootstrap,
  SaylI18n,
  
  SaylComponents,
  UtilsGmap
]
