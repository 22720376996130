import { default as SaylItems } from './sayl'
// import { default as SaylConn3ct } from './conn3ct'
// import { default as SaylCustomer } from './customer'
// import { default as SaylResto } from './resto'

import DefaultOrder from './default-order'
import { mapState } from 'vuex'

let list = SaylItems
  // .concat(SaylResto)
  // .concat(SaylCustomer)
  // .concat(SaylConn3ct)

let baseState = {
  'sayl': {
    class: 'n-primary-top__sayl',
    fqn: 'sayl',
    label: 'partner.product_sayl',
    logo: '/statics/images/logos/sayl/sayl-logomark-teal.svg',
    children: []
  },

  // 'sayl-resto':  {
  //   class: 'n-primary-top__sayl-resto',
  //   fqn: 'sayl-resto',
  //   label: 'sayl.product_resto',
  //   logo: '/statics/images/logos/sayl/sayl-logomark-red.svg',
  //   children: []
  // },

  // 'sayl-customer': {
  //   class: 'n-primary-top__sayl-customer',
  //   fqn: 'sayl-customer',
  //   label: 'sayl.product_customer',
  //   logo: '/statics/images/logos/sayl/sayl-logomark-green.svg',
  //   children: []
  // },

  // 'sayl-conn3ct': {
  //   class: 'n-primary-top__sayl-connect',
  //   fqn: 'sayl-conn3ct',
  //   label: 'sayl.product_conn3ct',
  //   logo: '/statics/images/logos/sayl/sayl-logomark-yellow.svg',
  //   children: []
  // }
}

export default {
  computed: {
    ...mapState({     
      bootstrap: state => state.sayl.bootstrap
    }),

    body() {
      let nav = DefaultOrder
      let actives = []
  
      Object.keys(nav).forEach(k => {
        let v = this.$basil.get(baseState, k)
        if(this.$basil.isNil(v)) {
          return
        }
  
        v.children = this.productChildren(nav[k])
        actives.push(v)
      })
  
      return actives
    },

    navigation() {
      return {
        body: this.body,
        
        footer: [],

        identity: {
          active: false,
          description: 'partner.profile_description',
          fqn: 'sayl-identity',
          icon: 'users',
          icons: ['star', 'user', 'award'],
          locked: false,
          children: [
            {
              active: false,
              description: 'partner.profile_description',
              fqn: 'sayl-identity-identity',
              icon: 'users',
              locked: false,
            }
          ]
        }
      }
    },
  },

  methods: {

    format(item) {
      let ret = null

      let fqn = this.$basil.get(item, 'fqn')
      if(this.$basil.isNil(fqn)) {
        return 
      }
      
      ret = list.find(l => l.fqn === fqn)

      if(this.$basil.isNil(ret)) {
        return 
      }

      let locked = this.$basil.get(ret, 'locked')

      if(!this.$basil.isNil(locked) && locked.length > 0) {
        ret.locked = ret.locked
          // .map(l => this.isRouteLocked(l))
          .reduce((acc, cur) => {
            return acc & cur
          }, true)
      }

      let children = this.$basil.get(item, 'children', [])
      if(!this.$basil.isNil(children) && children.length > 0) {
        ret.children = []
        children.forEach(c => {
          ret.children.push(this.format(c))
          ret.subs.push(c.fqn)
          ret.subs.push(this.$basil.get(c, 'subs', []))
          ret.subs = ret.subs.flat(0)
        }) 
      }
      
      return ret
    },

    productChildren(items) {
      let ret = []

      items.forEach(i => {
        let v = this.format(i)
        if(!this.$basil.isNil(v)) {
          ret.push(v)
        }
      })

      return ret
    },
  }
}
