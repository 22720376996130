import Pepper from '@spices/pepper'

/**
 * Register @spices/pepper
 * 
 * @param {Object} options
 * @param {GingerCapabilities} options.capabilities
 * @returns {Promise.resolve}
 */
export default function ({ capabilities }) {
  $console.group('spices-pepper')
  
  let Vue = capabilities.vue
  
  Vue.use( Pepper )
  
  $console.groupEnd('spices-pepper')
  return Promise.resolve()
}
