/**
 * @readonly
 * @property {Object} Ranges 
 * Ranges definitions 
 */
const Ranges = {}
let today = new Date()
let prefix = 'sayl.date_range_'

///////////////// Today
let todayFrom = today.setHours(0, 0, 0)
let todayTo = today.setHours(23, 59, 59)

/**
 * @property {Object} TODAY
 */
const TODAY = {
  label: `${prefix}today`,
  value: {
    from: todayFrom,
    to: todayTo,
    start: new Date(todayFrom),
    end: new Date(todayTo),
  },
  start: new Date(todayFrom),
  end: new Date(todayTo),
  toString() {
    return 'today'
  }
}
Ranges.TODAY = TODAY
/////////////////////////

///////////////// Yesterday
let yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
let yesterdayFrom = yesterday.setHours(0, 0, 0)
let yesterdayTo = yesterday.setHours(23, 59, 59)

/**
 * @property {Object} yesterday
 */
const YESTERDAY = {
  label: `${prefix}yesterday`,
  value: {
    from: yesterdayFrom,
    to: yesterdayTo,

    start: new Date(yesterdayFrom),
    end: new Date(yesterdayTo),
  },
  start: new Date(yesterdayFrom),
  end: new Date(yesterdayTo),
  toString() {
    return 'yesterday'
  }
}
Ranges.YESTERDAY = YESTERDAY
/////////////////////////

///////////////// Last 7 days
let last7d = new Date(Date.now() - (7 * 24 * 60 * 60 * 1000))
let last7dFrom = last7d.setHours(0, 0, 0)
let last7dTo = new Date(Date.now() - (1 * 24 * 60 * 60 * 1000)).setHours(23, 59, 59)

/**
 * @property {Object} LAST_7_DAYS
 */
const LAST_7_DAYS = {
  label: `${prefix}last_7_days`,
  value: {
    from: last7dFrom,
    to: last7dTo,

    start: new Date(last7dFrom),
    end: new Date(last7dTo),
  },
  start: new Date(last7dFrom),
  end: new Date(last7dTo),
  toString() {
    return 'last_7_d'
  }
}
Ranges.LAST_7_DAYS = LAST_7_DAYS
/////////////////////////

///////////////// This Week
let thisWeek = new Date(new Date().setHours(0, 0, 0))
let thisWeekFrom = thisWeek.setDate(thisWeek.getDate() - thisWeek.getDay() + 1)
let thisWeekTo = new Date(new Date().setHours(23, 59, 59))

/**
 * @property {Object} 
 */
 const THIS_WEEK = {
  label: `${prefix}this_week`,
  value: {
    from: thisWeekFrom,
    to: thisWeekTo,

    start: new Date(thisWeekFrom),
    end: new Date(thisWeekTo),
  },
  start: new Date(thisWeekFrom),
  end: new Date(thisWeekTo),
  toString() {
    return 'this_week'
  }
}
Ranges.THIS_WEEK = THIS_WEEK
/////////////////////////

///////////////// Last Week
let lastWeek = new Date(new Date(new Date(Date.now() - (6 * 60 * 60 * 24 * 1000)).setHours(0, 0, 0)))
let lastWeekFrom = new Date(lastWeek.setDate(lastWeek.getDate() - lastWeek.getDay() + 1))
let lastWeekTo = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(lastWeek.getDate() - lastWeek.getDay() + 7))

/**
 * @property {Object} 
 */
 const LAST_WEEK = {
  label: `${prefix}last_week`,
  value: {
    from: lastWeekFrom,
    to: lastWeekTo,

    start: new Date(lastWeekFrom),
    end: new Date(lastWeekTo),
  },
  start: new Date(lastWeekFrom),
  end: new Date(lastWeekTo),
  toString() {
    return 'last_week'
  }
}
Ranges.LAST_WEEK = LAST_WEEK
/////////////////////////

///////////////// Last 30 days
let last30d = new Date(Date.now() - (30 * 24 * 60 * 60 * 1000))
let last30dFrom = last30d.setHours(0, 0, 0)
let last30dTo = new Date(Date.now() - (1 * 24 * 60 * 60 * 1000)).setHours(23, 59, 59)

/**
 * @property {Object} LAST_30_DAYS
 */
const LAST_30_DAYS = {
  label: `${prefix}last_30_days`,
  value: {
    from: last30dFrom,
    to: last30dTo,

    start: new Date(last30dFrom),
    end: new Date(last30dTo),
  },
  start: new Date(last30dFrom),
  end: new Date(last30dTo),
  toString() {
    return 'last_30_d'
  }
}
Ranges.LAST_30_DAYS = LAST_30_DAYS
/////////////////////////

///////////////// This month
let thisMonth = new Date(today.setDate(1))
let thisMonthFrom = thisMonth.setHours(0, 0, 0)
let thisMonthTo = new Date(new Date().setHours(23, 59, 59))

/**
 * @property {Object} THIS_MONTH
 */
const THIS_MONTH = {
  label: `${prefix}this_month`,
  value: {
    from: thisMonthFrom,
    to: thisMonthTo,

    start: new Date(thisMonthFrom),
    end: new Date(thisMonthTo),
  },
  start: new Date(thisMonthFrom),
  end: new Date(thisMonthTo),
  toString() {
    return 'this_month'
  }
}
Ranges.THIS_MONTH = THIS_MONTH
/////////////////////////

///////////////// Last month
let lastMonth = new Date(new Date(today.setMonth(today.getMonth() - 1)).setDate(1))
let lastMonthFrom = lastMonth.setHours(0, 0, 0)
let lastMonthTo = new Date(new Date(new Date().setDate(0)).setHours(23, 59, 59))

/**
 * @property {Object} LAST_MONTH
 */
const LAST_MONTH = {
  label: `${prefix}last_month`,
  value: {
    from: lastMonthFrom,
    to: lastMonthTo,

    start: new Date(lastMonthFrom),
    end: new Date(lastMonthTo),
  },
  start: new Date(lastMonthFrom),
  end: new Date(lastMonthTo),
  toString() {
    return 'last_month'
  }
}
Ranges.LAST_MONTH = LAST_MONTH
/////////////////////////

///////////////// Last 3 months
let last3Months = new Date(new Date(today.setMonth(today.getMonth() - 2)).setDate(1))
let last3MonthsFrom = last3Months.setHours(0, 0, 0)
let last3MonthsTo = new Date(new Date(new Date().setDate(0)).setHours(23, 59, 59))

/**
 * @property {Object} LAST_3_MONTHS
 */
const LAST_3_MONTHS = {
  label: `${prefix}last_3_months`,
  value: {
    from: last3MonthsFrom,
    to: last3MonthsTo,

    start: new Date(last3MonthsFrom),
    end: new Date(last3MonthsTo),
  },
  start: new Date(last3MonthsFrom),
  end: new Date(last3MonthsTo),
  toString() {
    return 'last_3_m'
  }
}
Ranges.LAST_3_MONTHS = LAST_3_MONTHS
/////////////////////////

///////////////// This year
let thisYear = new Date(new Date(new Date().setMonth(0)).setDate(1))
let thisYearFrom = thisYear.setHours(0, 0, 0)
let thisYearTo = new Date().setHours(23, 59, 59)

/**
 * @property {Object} THIS_YEAR
 */
const THIS_YEAR = {
  label: `${prefix}this_year`,
  value: {
    from: thisYearFrom,
    to: thisYearTo,

    start: new Date(thisYearFrom),
    end: new Date(thisYearTo),
  },
  start: new Date(thisYearFrom),
  end: new Date(thisYearTo),
  toString() {
    return 'this_year'
  }
}
Ranges.THIS_YEAR = THIS_YEAR
/////////////////////////

///////////////// Last year
let lastYear = new Date(new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setMonth(0)).setDate(1))
let lastYearFrom = lastYear.setHours(0, 0, 0)
let lastYearTo = new Date(new Date(lastYear.setFullYear(lastYear.getFullYear() + 1)).setDate(0)).setHours(23, 59, 59)

/**
 * @property {Object} LAST_YEAR
 */
const LAST_YEAR = {
  label: `${prefix}last_year`,
  value: {
    from: lastYearFrom,
    to: lastYearTo,

    start: new Date(lastYearFrom),
    end: new Date(lastYearTo),
  },
  start: new Date(lastYearFrom),
  end: new Date(lastYearTo),
  toString() {
    return 'last_year'
  }
}
Ranges.LAST_YEAR = LAST_YEAR
/////////////////////////

///////////////// Last 12 months
let last12Months = new Date(new Date(new Date().setMonth(new Date().getMonth() - 12)).setDate(1))
let last12MonthsFrom = last12Months.setHours(0, 0, 0)
let last12MonthsTo = new Date(new Date().setDate(0)).setHours(23, 59, 59)

/**
 * @property {Object} LAST_12_MONTHS
 */
const LAST_12_MONTHS = {
  label: `${prefix}last_12_months`,
  value: {
    from: last12MonthsFrom,
    to: last12MonthsTo,

    start: new Date(last12MonthsFrom),
    end: new Date(last12MonthsTo),
  },
  start: new Date(last12MonthsFrom),
  end: new Date(last12MonthsTo),
  toString() {
    return 'last_12_m'
  }
}
Ranges.LAST_12_MONTHS = LAST_12_MONTHS
/////////////////////////

///////////////// Custom
/**
 * @property {Object} CUSTOM
 */
const CUSTOM = {
  label: `${prefix}custom`,
  value: {
    from: null,
    to: null,

    start: null,
    end: null,
  },
  start: null,
  end: null,
  toString() {
    return 'custom'
  }
}
Ranges.CUSTOM = CUSTOM
/////////////////////////

/**
 * Search for an range based on the value
 * 
 * @param {Object} options 
 * @param {Number} options.from 
 * @param {Number} options.to
 * 
 * @return {Object} range
 */
Ranges.getByValue = ({ from, to }) => {
  let range = Ranges.ALL.find(r => r.value.from === from && r.value.to === to)
  return range != null ? range : Ranges.CUSTOM
}

/**
 * @readonly
 * @property {Object} ALL 
 * Ranges ALL
 */
Ranges.ALL = [
  TODAY,
  YESTERDAY,
  LAST_7_DAYS,
  THIS_WEEK,
  LAST_WEEK,
  LAST_30_DAYS,
  THIS_MONTH,
  LAST_MONTH,
  LAST_3_MONTHS,
  LAST_YEAR,
  LAST_12_MONTHS,
]

/**
 * @readonly
 * @property {Object} ABSOLUTE 
 * Ranges ABSOLUTE
 */
Ranges.ABSOLUTE = [
  TODAY,
  YESTERDAY,
  LAST_WEEK,
  THIS_WEEK,
  LAST_MONTH,
  THIS_MONTH,
  LAST_YEAR,
  THIS_YEAR,
]

/**
 * @readonly
 * @property {Object} RELATIVE 
 * Ranges RELATIVE
 */
Ranges.RELATIVE = [
  TODAY,
  YESTERDAY,
  LAST_7_DAYS,
  LAST_30_DAYS,
  LAST_3_MONTHS,
  LAST_12_MONTHS
]

export default Ranges
