<template>
  <layout-base class="layout-level2">
    <!-- Header -->
    <nav-header v-bind="header">
      <template slot="extra">
        <slot name="header"></slot>  
      </template>  

      <template v-slot:filters>
        <slot name="filters"></slot>
      </template>
    </nav-header>

    <ui-errors 
      :errors="errors"
      @back="$emit('back')">
      <!-- Body -->
      <div class="layout-level2__body">
        <!-- Inheritance parent -> [child] -->
        <notice-banner 
          v-if="inheritedFromParent"
          class="layout-level2__banner"
          :intent="$pepper.Intent.WARNING">
          <template v-slot:title>{{ $t('partner.inheritance_parent_title') }}</template>
          <p>{{ $t('partner.inheritance_parent_description') }}</p>
        </notice-banner>

        <!-- Inheritance [parent] -> child -->
        <notice-banner
          v-if="inheritedFromChild"
          class="layout-level2__banner">
          <template v-slot:title>{{ $t('partner.inheritance_child_title') }}</template>
          <p>{{ $t('partner.inheritance_child_description') }}</p>
        </notice-banner>
        
        <div 
          class="layout-level2__loader" 
          v-if="isLoading">
          <ui-loader />
        </div>

        <!-- Data -->
        <slot v-else></slot>

        <forms-save 
          v-if="!pristine"
          @save="save"
        />
      </div>

      <!-- Extra actions -->
      <popins-list-dropdown
        v-if="false"
        :target="$refs.extra"
        position="bottom-center"
        :visible="showExtra"
        :layout="['label']"
        :options="extraActions"
      />
      
    </ui-errors>
  </layout-base>
</template>

<script>
import { 
  MixinHeader, 
  MixinInheritance
} from '@sayl/admin-common'

import NavHeader from '../components/navigations/header'

export default {
  name: "LayoutLevel2",

  components: {
    NavHeader
  },

  mixins: [
    MixinHeader,
    MixinInheritance,
  ],

  props:{
    errors: {
      type: Object,
      default:() => { 
        return {} 
      }
    },
  },

  computed: {
    classes() {
      return Object.assign(
        {
          'layout-level2': true,
          'layout-level2-sided': true
        },
        this.$attrs.class
      )
    },
  },
};
</script>
