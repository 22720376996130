<template>
  <layout-modal
    :closable="true"
    :title="$t('partner.modal_create_title')"
    :description="$t('partner.modal_create_description')" 
    :visible="true"
    @close="onClose"
    @back="onClose"
    @discard="onClose"
    v-if="openModal">
    <template v-if="loading">
      <div class="layout-modal__loader">
        <ui-loader />
      </div>
    </template>
    
    <template v-else>
      <article class="flow" v-if="!loading">
        <forms-input
          :errors="getErrors('name')"
          :placeholder="$t('partner.client_name')"
          required
          type="text"
          @input="onRemoveError('name')"
          v-model="value.name"
        >{{ $t('partner.client_name') }}</forms-input>

        <forms-input
          :errors="getErrors('email')"
          :placeholder="$t('partner.client_email')"
          required
          type="email"
          @input="onRemoveError('email')"
          v-model="value.email"
        >{{ $t('partner.client_email') }}</forms-input>

        <forms-input
          :errors="getErrors('sender')"
          :placeholder="$t('partner.sender_name')"
          required
          type="text"
          @input="onRemoveError('sender')"
          v-model="value.sender"
        >{{ $t('partner.sender_name') }}</forms-input>
      </article>
    </template> 

    <template v-slot:footer>
      <actions-button
        :appearance="$pepper.Appearance.DEFAULT"
        @click="onClose"
      >{{ $t('partner.close') }}</actions-button>

      <actions-button
        appearance="primary"
        :disabled="loading"
        icon-post="arrow-right"
        @click="onConfirm"
      >{{ $t('partner.save') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import { MixinError } from '@sayl/admin-common'

export default {
  name: 'CreateModal',

  mixins: [
    MixinError,
  ],
  
  props: {
    value: {
      type: Object,
      required: true
    },

    openModal: {
      type: Boolean,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true
    },

    errors: {
      type: Object,
      required: true
    },

    onClose: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      key: 1
    }
  },

  computed: {

  },

  methods: {
    onConfirm() {
      this.$emit('submit', this.value)
    }
  },
}
</script>
