import { CpApi, http } from './api'
import { isDevelopment } from '@/env'

/**
 * @class
 */
export default class CpController {
  
  /**
   * @constructor
   * 
   * @param {VueX} store The vuex instance
   * @param {Vue} Vue The vue instance
   */
  constructor({ store, Vue }){
    this._store = store
    this._Vue = Vue

    this._data = {}
  }

  /////////////////////////////////////////////////////////////////////

  /**
   * @property {Object} data The bootstrap data
   */
  get data(){
    return this._data
  }

  /////////////////////////////////////////////////////////////////////

  /**
   * Fetch the bootstrap configuration
   *  - from the (client) server
   *  - from the (client) filesystem in development 
   * 
   * @returns {Promise} 
   *  If the user is authenticated, the promise will be resolved
   *  Else the promise will fail
   */
  bootstrap(){
    return new Promise((resolve, reject) => {
      $console.info('dl.cp.bootstrap')

      basil.sequence([ this.server.bind(this) ])
        .then(() => resolve(this._data))
        .catch(e => reject(e))
    })
  }

  /**
   * Fetch the bootstrap configuration from the (client) server
   *
   * @returns {Promise}
   *  If the user is authenticated, the promise will be resolved
   *  Else the promise will fail
   */
  server(){
    return new Promise((resolve, reject) => {
      $console.info('dl.cp.server')
      
      CpApi.bootstrap()
        .then(response => {
          this._data = response.data
          
          // Make sure the user is authenticated
          if (basil.get(this._data, 'user.profile') === null) {
            return reject({ response: { status: 401, message: 'not_authenticated' } })
          }
          
          // Start the keep alive
          this.keepAlive({ })
  
          // Save the config in the store
          this._store.commit('sayl/setBootstrap', this._data)

          return resolve(this._data)
        })
        .catch(e => reject(e))
    })

  }

  /**
   * Override the global configuration with a local config file
   * 
   * @returns {Promise}
   */
  development(){
    return new Promise((resolve, reject) => {
      if (!isDevelopment){
        return resolve()
      }

      $console.debug('dl.cp.development')
      
      http.get('/statics/json/bootstrap.json')
        .then(response => {
          let data = response.data
          this._data = Object.assign(this._data, data)

          // Save the config in the store
          this._store.commit('sayl/setBootstrap', this._data)
  
          return resolve(this._data)
        })
    })

  }

  /**
   * Start an interval call to the cp in order to keep the 
   * session alive.
   * 
   * @param {Object} options The options
   * @param {Number} options.interval The interval to call home in ms
   */
  keepAlive({ interval = 1000 * 60 * 5 }){
    setInterval(CpApi.keepAlive.bind(CpApi), interval)
  }
}
