<template>
  <section v-bind="attrs">
    <div class="n-primary-top__display">

      <!-- Body -->
      <div class="n-primary-top__body">
        <section 
          v-for="(n, i) in navigation.body"
          :key="i"
          class="n-primary-top__section"
          :class="n.class">
          
          <h2 class="n-primary-top__title">
            <span class="n-primary-top__figure">
              <img
                v-if="n.logo"
                :alt="n.label"
                class="n-primary-top__logo" 
                :src="$getImageSrc(n.logo)">
            </span>

            <span class="n-primary-top__label">{{ $t(n.label) }}</span>
          </h2>

          <!-- Entries -->
          <div class="n-primary-top__entries">
            <button
              v-for="(m, i) in n.children"
              :key="i"
              :class="getEntryClasses(m)"
              @click="onClick(m)"
              :id="m.fqn"
              @mouseenter="onEntryOver(m)"
              @mouseleave="onEntryOut(m)">
              
              <!-- Icon -->
              <ui-icon
                class="n-primary-top__icon" 
                :glyph="m.icon" />

              <!-- Label -->
              <span class="n-primary-top__module">{{ $t(m.label) }}</span>

              <!-- Children? -->
              <ui-icon 
                v-if="m.children"
                class="n-primary-top__children"
                glyph="angle-right" />

              <!-- Lock -->
              <div 
                v-if="m.locked"
                class="n-primary-top__restricted">
                <ui-icon 
                  class="n-primary-top__lock"
                  glyph="lock" />
              </div>
            </button>
          </div>

        </section>
      </div>

      <footer class="n-primary-top__footer">
        <section 
          v-for="(n, i) in navigation.footer"
          :key="i"
          class="n-primary-top__section"
          :class="n.class">
          
          <h2 class="n-primary-top__title">
            <span class="n-primary-top__label">{{ $t(n.label) }}</span>
          </h2>

          <!-- Entries -->
          <div class="n-primary-top__entries">
            <button
              v-for="(m, i) in n.children"
              :key="i"
              :class="getEntryClasses(m)"
              @click="onClick(m)"
              :id="m.fqn"
              @mouseenter="onEntryOver(m)"
              @mouseleave="onEntryOut(m)">
              
              <!-- Icon -->
              <ui-icon
                class="n-primary-top__icon" 
                :glyph="m.icon" />

              <!-- Label -->
              <span class="n-primary-top__module">{{ $t(m.label) }}</span>

              <!-- Lock -->
              <div 
                v-if="m.locked"
                class="n-primary-top__restricted">
                <ui-icon 
                  class="n-primary-top__lock"
                  glyph="lock" />
              </div>
            </button>
          </div>

        </section>
      </footer>
    </div>

    <!-- Tooltip -->
    <transitions-fade 
      :direction="$pepper.Direction.RIGHT">
      <div 
        v-if="tooltip"
        class="n-primary-top__tooltip tooltip"
        ref="tooltips"
        :style="tooltipStyles">
        <article
          class="tooltip__item"
          v-for="(t, i) in yChildren"
          :key="i" 
          >
          <!-- Tooltip::Label -->
          <span class="tooltip__label">{{ $t(t.label) }}</span>
        </article>
      </div>
    </transitions-fade>

    <!-- Focus -->
    <div 
      class="n-primary-top__focus"
      :class="{ '-is-active': hasActiveSelection }"
      :style="focusStyles"></div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PrimaryTopPane',

  data(){
    return {
      activeEntry: null,
      interval: null,
      tInterval: null,

      /**
       * @property {Boolean} tooltip
       *  Whether or not to display the tooltip
       */
      tooltip: false,
    }
  },

  props: {
    /**
     * @property {Boolean} active
     *  Whether or not the focus is on this view
     */
    active: {
      type: Boolean,
      default: false
    },

    /**
     * @property {Object} entry 
     *  The current selected entry
     */
    routeEntry: {
      type: Object,
      default: () => {}
    },

    /**
     * @property {Object} navigation
     *  The master navigation tree
     */
    navigation: {
      type: Object,
      default: () => { return {body: {}, footer: {}} }
    },

    /**
     * @property {Boolean} opened
     *  Whether or not the whole navigation is opened (vs closed)
     */
    opened: {
      type: Boolean,
      default: true
    },
    
    /**
     * @property {Object} selection
     *  The current selected entry.
     */
    selection: {
      type: Object,
      default: () => {}
    },

    /**
     * @property {Object} x
     *  The displayed x level entry
     */
    x: {
      type: Object,
      default: () => {}
    },

    /**
     * @property {Object} y
     *  The displayed y level entry
     */
    y: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters({
      config: 'sayl/config',
      user: 'sayl/user',
    }),

    attrs(){
      return {
        class: this.classes
      }
    },

    classes(){
      return {
        'n-primary-top': true,
        '-is-active': this.active === true,
        '-is-opened': this.isOpen === true,
      }
    },

    entries(){
      return this.navigation.filter(e => e.fqn !== 'profile')
    }, 

    fqn(){
      return this.module
    },

    focusStyles(){
      let ret = {
        top: '-10%'
      }

      let id = this.$basil.get(this.activeEntry, 'fqn', null)
      let e = document.getElementById(id)
      
      if (e){
        let b = e.getBoundingClientRect()
        let y = b.top - (1 * 16) - (2 * 1); // 1rem -> padding-top - (2px -> border) 

        ret = {
          top: `${y}px`,
          height: `${b.height}px`
        }
      }

      return ret
    },

    /**
     * @property {Boolean} hasEntry
     *  Whether or not we have an active entry
     */
    hasEntry(){
      return !!this.activeEntry
    },

    /**
     * @property {Boolean} hasSelection
     *  Whether or not there is a selected entry
     */
    hasActiveSelection(){
      return !!this.selection
    },

    hasActiveSelectionChildren(){
      return this.hasActiveSelection === true && 
             !!this.selection.children &&
             this.selection.children.length > 0
    },

    isPaneIdentity(){
      return this.$basil.get(this.selection, 'fqn') === 'sayl-identity'
    },

    /**
     * @property {Boolean} isOpen
     *  Whether to display the view opened / closed
     */
    isOpen(){
      return this.opened === true && 
             !(this.hasActiveSelection && this.hasActiveSelectionChildren)
    },
    
    /**
     * @property {Object} tooltipStyles
     *  The style for the tooltip
     */
    tooltipStyles(){
      let ret = {
      }

      let id = this.$basil.get(this.activeEntry, 'fqn', null)
      let e = document.getElementById(id)
      
      if (e){
        let rem = 16
        let h = 2.5 * rem
        let max = window.innerHeight - 2 * rem - h - .25 * rem;
        let min = 1 * rem;

        let b = e.getBoundingClientRect()
        let y = b.top - (1 * rem) - (2 * 1); // 1rem -> padding-top - (2px -> border) 
        y = y - ((h - b.height) / 2)
        y = Math.min(max, Math.max(min, y))

        ret = {
          top: `${y}px`,
        }
      }

      return ret
    },

    /**
     * @property {Array} yChildren
     *  All the y items 
     */
    yChildren(){
      let ret = []
      this.navigation.body.forEach(x => {
        x.children.forEach(y => {
          ret.push(y)
        })
      })

      return ret
    },
  },

  methods: {

    /**
     * Generate the class list for the given entry
     * @method 
     * @param {String} entry
     * @returns {Object}
     */
    getEntryClasses(entry){
      return {
        'n-primary-top__entry': true,
        '-is-active': entry.active === true,
        '-is-selected': this.isEntrySelected(entry)
      }
    },

    /**
     * Whether or not the given entry is selected
     * @param {Object} entry
     * @returns {Boolean}
     */
    isEntrySelected(entry){
      return this.$basil.get(this.activeEntry, 'fqn') === entry.fqn
    },

    /**
     * A click on an entry.
     * @method
     * @param {Object} entry
     */
    onClick(entry){
      if (entry.href){
        this.$router.push(entry.href).catch(() => {});
      }
      
      this.$emit('selection', entry)
    },

    /**
     * A mouse over on an entry.
     * @method
     * @param {Object} entry
     */
    onEntryOver(entry){
      this.activeEntry = entry
      clearInterval(this.interval)

      if (this.tooltip){
        this.refreshTooltip()
      }
      else{
        clearInterval(this.tInterval)
        this.tInterval = setTimeout(() => {
          this.showTooltip()
        }, 1000)
      }
    },

    /**
     * A mouse out on an entry.
     * @method
     * @param {Object} entry
     */
    onEntryOut(entry){
      clearInterval(this.tInterval)
      this.interval = setTimeout(() => {
        this.hideTooltip()
        this.reset()
      }, 500);
    },

    /**
     * Reset the view
     */
    reset(){
      this.activeEntry = this.selection
    },

    // /////////////////////////////////////////////////////////
    // 
    // Tooltip
    // 
    // /////////////////////////////////////////////////////////

    /**
     * Refresh the tooltip to display
     */
    refreshTooltip(){
      this.$nextTick(() => {
        let i = this.yChildren.findIndex(e => e.fqn === this.activeEntry.fqn)
        let rem = 16;
        let h = 2.5 * rem; // 2.5rem
        let sy = i * h
        this.$refs.tooltips.scrollTo({ top: sy, behavior: 'smooth' })
      })
    },

    /**
     * Display the tooltip
     */
    showTooltip(){
      // Disable the tooltip 
      //  - for touch devices
      //  - if the navigation is open
      if (navigator.maxTouchPoints > 0 || this.opened === true){
        this.tooltip = false
        return
      }
      this.tooltip = true
      this.refreshTooltip()
    },

    /**
     * Hide the tooltip
     */
    hideTooltip(){
      this.tooltip = false
    },
  },

  mounted(){
    this.reset()
  }
}
</script>
