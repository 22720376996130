<template>
  <div class="data-table-cell-products data-table-cell-group group">
    <!-- <div 
      :class="product.class" -->
      <img
        class="data-table-cell-products__image"
        :key="`product_${product.title}`"
        v-for="product in products"
        :alt="product.title"
        :title="product.title"
        :src="product.image"
      />
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: 'DataTableCellProducts',

  props: {
    values: {
      type: Array, 
      required: true,
    },
  },

  computed: {
    products() {
      return this.values.map((v) => {
        return {
          title: this.$t(`sayl-partner.product_sayl_${v}`),
          image: this.$getImageSrc(`/statics/images/logos/sayl/sayl-logomark-${this.getColor(v)}.svg`)
        }
      })
    }, 
  },

  methods: {
    getColor(product) {
      let ret = 'teal'

      switch(product) {
        case 'sayl_resto': ret = 'red'; break;
        case 'sayl_customer': ret = 'green'; break;
        case 'sayl_conn3ct': ret = 'yellow'; break;
        case 'sayl_retail': ret = 'pink'; break;
      }

      return ret
    }
  }
}
</script>
