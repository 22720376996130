<template>
  <popins-modal
    :closable="true"
    :klass="classes"
    :visible="visible"
    position="top-center"
    size="l"
    v-on="$listeners"
    @close="close">

    <div class="container -cols modal-feature__body">
      <!-- FEATURE -->
      <div class="col modal-feature__presentation feature">
        <div class="feature__brand">{{ $t(`sayl-feature.${featureName}_name`) }}</div>

        <div class="feature__heading">
          <h3 class="feature__title">{{ $t(`sayl-feature.${featureName}_title`) }}</h3>
          <div class="feature__description">{{ $t(`sayl-feature.${featureName}_description`) }}</div>
        </div>

        <div class="feature__actions">
          <actions-button
            class="feature__action"
            :href="link"
            :size="$pepper.Size.S"
            target="_blank"
          >{{ $t(`sayl-feature.learn_more`) }}</actions-button>
        </div>

        <div class="feature__figures">
          <div class="feature__figure-background"></div>
          
          <div 
            class="feature__figure" 
            :style="`background-image: url('${featureImage}')`"
          ></div>  
        </div>

        <div class="feature__icons">
          <div 
            class="feature__icon"
            :key="$basil.uniqId(index)"
            v-for="(feat, index) in features">
            
            <div class="icon"><ui-icon :glyph="feat.icon" /></div>
            <div class="title">{{ feat.label }}</div>
          </div>
        </div>
      </div>

      <!-- PRODUCT -->
      <div class="col modal-feature__product">
        <!-- PRODUCT LOGO -->
        <div class="modal-feature__branding">
          <img 
            alt="sayl-customer"
            :src="$getImageSrc(`/statics/images/logos/sayl/${branding}`)" 
          />
        </div>

        <!-- TITLE  -->
        <div class="modal-feature__heading">
          <h3 class="title">{{ $t(`sayl-feature.${productName}_title`) }}</h3>
          <p class="description">{{ $t(`sayl-feature.${productName}_description`) }}</p>
        </div>

        <!-- PRODUCT FEATURES -->
        <div class="modal-feature__features">
          <div 
            class="modal-feature__feature"
            :key="$basil.uniqId(index)"
            v-for="(feat, index) in productFeatures">
            <div class="icon">
              <ui-icon :glyph="feat.icon" />
            </div>

            <div class="content">
              <div class="title">{{ feat.title}}</div>
              <div class="description">{{ feat.description }}</div>
            </div>
          </div>
        </div>

        <!-- Request DEMO -->
        <div class="modal-feature__actions" v-if="product !== 'sayl-conn3ct'">
          <actions-button
            class="modal-feature__action"
            :href="link"
            target="_blank"
          >{{ $t('sayl-feature.request_demo') }}</actions-button>
        </div>
      </div>
    </div>

  </popins-modal>
</template>

<script>
export default {
  name: 'ModalFeature',

  props: {
    product: {
      type: String,
      required: true,
    },

    feature: {
      type: String,
      required: true
    },

    visible: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        'modal-feature': true,
        [this.theme] : true
      }
    },

    attrs() {
      return {
        visible: this.visible
      }
    },

    branding() {
      switch(this.product) {
        case 'sayl-customer':
          return 'sayl-customer-color.svg'
        
        case 'sayl-conn3ct':
          return 'sayl-conn3ct-color.svg'
        
        case 'sayl-resto':
          return 'sayl-resto-color.svg'
      
        case 'sayl-retail':
          return 'sayl-retail-color.svg'
      }
    },

    linkDemo() {
      switch(this.product) {
        case 'sayl-customer':
          return 'https://saylcloud.com/en/customer/demo/'
        
        case 'sayl-resto':
          return 'https://saylcloud.com/en/resto/demo/'
      
        case 'sayl-retail':
          return 'https://saylcloud.com/en/retail/demo/'

        case 'sayl-conn3ct':
          return 'https://saylcloud.com/en/sales'
      }
    },

    link() {
      switch(this.product) {
        case 'sayl-customer':
          return 'https://saylcloud.com/en/customer/pricing'
        
        case 'sayl-resto':
          return 'https://saylcloud.com/en/resto/pricing'
      
        case 'sayl-retail':
          return 'https://saylcloud.com/en/retail/pricing'

        case 'sayl-conn3ct':
          return 'https://saylcloud.com/en/connect'
      }
    },

    featureImage() {
      return this.$t(`sayl-feature.${this.featureName}_image`)
    },

    features() {
      return [
        {
          icon: this.$t(`sayl-feature.${this.featureName}_feature_1_icon`), //'award',
          label: this.$t(`sayl-feature.${this.featureName}_feature_1_label`), //'Reward your regulars'
        },
        {
          icon :this.$t(`sayl-feature.${this.featureName}_feature_2_icon`), //'chart-line',
          label: this.$t(`sayl-feature.${this.featureName}_feature_2_label`), //'Track sales impact'
        }
      ]
    },

    featureName() {
      return this.feature.split('-').join('_')
    },

    productName() {
      return this.product.split('-').join('_')
    },

    productFeatures() {
      return [
        {
          icon: this.$t(`sayl-feature.${this.productName}_feature_1_icon`), //  'bag'
          title:  this.$t(`sayl-feature.${this.productName}_feature_1_label`), // 'From acquisition to activation',
          description:  this.$t(`sayl-feature.${this.productName}_feature_1_description`), // 'Enable tailored, consistent and real-time customer experiences.'
        },

         {
          icon: this.$t(`sayl-feature.${this.productName}_feature_2_icon`), //  'face-id'
          title:  this.$t(`sayl-feature.${this.productName}_feature_2_label`), // 'Personalize every interaction',
          description:  this.$t(`sayl-feature.${this.productName}_feature_2_description`), // 'Activate your customers at the right moment with the right product.'
        },

         {
          icon: this.$t(`sayl-feature.${this.productName}_feature_3_icon`), //  'award',
          title:  this.$t(`sayl-feature.${this.productName}_feature_3_label`), // 'Reward your regulars',
          description:  this.$t(`sayl-feature.${this.productName}_feature_3_description`), // 'Get informed about your customers habits, and reward them with discounts.'
        },
      ];
    },

    theme() {
      switch(this.product) {
        case 'sayl-customer':
          return '-customer'
        
        case 'sayl-conn3ct':
          return '-conn3ct'
        
        case 'sayl-resto':
          return '-resto'
      
        case 'sayl-retail':
          return '-retail'
      }
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },
  }
}
</script>
