<template>
  <layout-level1 v-bind="attrs">
    
  </layout-level1>
</template> 

<script>
import { mapState } from 'vuex'

export default {
  name: 'PartnerPageHome',

  computed: {
    ...mapState({

    }),

    attrs() {
      return {
        breadcrumb: this.breadcrumb,
        description: this.description,
        image: this.image,
        title: this.title,
      }
    },

    breadcrumb() {
      return [
        { label: this.title, image: this.image }
      ]
    },

    description() {
      return this.$t('partner.home_description')
    },

    image() {
      return '/statics/images/logos/sayl/sayl-logomark-teal.svg'
    },

    title() {
      return this.$t('partner.home_title')
    }
  }
}
</script>
