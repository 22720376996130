<template>
  <div v-bind="attrs">
    <div class="data-state__icon">
      <ui-icon class="data-state__glyph" :glyph="glyph" />
    </div>

    <div 
      v-if="hasLabel"
      class="data-state__label"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: 'DataState',

  props: {
    appearance: {
      type: String, 
      default: 'default'
    },

    icon: {
      type: String
    },

    state: {
      type: String,
      default: 'success',
      values: ['errored', 'loading', 'success', 'warning'],
      validator: (value) => ['errored', 'loading', 'success', 'warning'].includes(value)
    },
  },

  computed: {
    attrs(){
      return {
        class: this.classes
      }
    },

    classes(){
      return {
        'data-state': true,

        '-is-errored': this.state === 'errored',
        '-is-loading': this.state === 'loading',
        '-is-success': this.state === 'success',
        '-is-warning': this.state === 'warning',
      }
    },

    glyph(){
      let ret;
      switch (this.state){
        case 'errored':
          ret = 'cross';
          break;
        case 'loading':
          ret = 'sync';
          break;
        default:
        case 'success':
          ret = 'check';
          break;
        case 'warning': 
          ret = 'bell';
          break;
      }

      return this.icon || ret
    },

    hasLabel() {
      return !!this.$slots.default
    }
  }
}
</script>
