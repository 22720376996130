import { basil } from '@spices/basil'
import { config } from '@/env'

export default { 
  namespaced: true,
  
  state: {
    /**
     * @property {Object} bootstrap 
     *  The data from the bootstrap call
     */
    bootstrap: {},

    /**
     * @property {Boolean} burger 
     *  Whether or not the burger menu is active (displayed)
     */
    burger: false,

    currentRoute: null,
    parentRoute: null,

    countries: null,

    /**
     * @property {Boolean} gmapInitialized 
     *  Whether or not the gmap lib is initialized
     */
    gmapInitialized: false,

    /**
     * @property {Boolean} gmapLoaded 
     *  Whether or not the gmap library is loaded
     */
    gmapLoaded: false,

    /**
     * @property {Array.<Role>} roles
     *  The list of all the roles
     */
    roles: [],

    /**
     * @property {Array.<Resource>} resources
     *  The list of all the resources
     */
    resoures: [],

    /**
     * @property {Number} state
     *  The application current state
     */
    state: 0
  },

  getters: {
    /**
     * @property {Object} config 
     *  The global configuration
     * @readonly 
     */
    config: state => config,

    /**
     * @property {Array} countries 
     *  All countries around the world
     * @readonly 
     */
    countries: state => state.countries,

    /**
     * @property {Array.<String>} favs 
     *  The favorites routes
     * @readonly
     */
    favs: state => JSON.parse(localStorage.getItem('sayl.favs') || "[]"),

    /**
     * @property {Boolean} isAuthenticated 
     *  Whether or not the user is authenticated
     * @readonly 
     */
    isAuthenticated: state => basil.get(state, 'bootstrap.user', null) !== null,

    /**
     * @property {Boolean} isImpersonating
     *  Whether or not the user is impersonating another user
     * @readonly
     */
    isImpersonating: state => basil.get(state, 'bootstrap.user.is_impersonating', false),
    
    /**
     * @property {String} gmapApiKey 
     *  The google map api key
     * @readonly
     */
    gmapApiKey: state => basil.get(state, 'bootstrap.google.map.key', null),
    
    /**
     * @property {Array} navigation
     *  The navigation based on the modules list
     * @readonly
     */
    navigation: (state, getters, rootState, rootGetters ) => {
      let gn = rootGetters['ginger/navigation']
      let ret = gn.map(m => {
        let cm = basil.get(rootState, 'ginger.module', {})
        let fqn = m.module.fqn
        fqn = fqn === 'audience' ? 'audiences' : fqn
        let extension = fqn === 'askemma' ? 'oat' : fqn

        let children = m.children.map(e => {
          let resource = e.split('_')
          resource.shift();
          resource = resource.join('_')

          return {
            description: `sayl-${fqn}.${resource}_description`,
            label: `sayl-${fqn}.${resource}`,
            fqn: resource,
            href: { name: e }
          }
        })

        // Custom navigation for the integration
        if (fqn === 'integration'){
          let l = (localStorage.getItem('sayl-integration-history') || '').trim()
          l = l.length > 0 ? l.split('|') : []
          if(l.length > 0) {
            l.length = 4
            let c = l.map(e => {
              return {
                description: `sayl-${fqn}.${fqn}_${e}_description`,
                label: `sayl-${fqn}.${fqn}_${e}_label`,
                fqn: e,
                href: { name: 'sayl-integration_presentation', params: { module: e }}
              }
            })
            children = children.concat(c)
          }
        }

        // children = children.filter(c => {
        //   // @TODO Filter based on the acl
        //   //  => this.isAllowed(resource, this.$basil.acl.VIEW))
        //   //  => cfr to mixin acl in sayl common

        //   return !basil.sayl.EXTENSIONS.includes(c.resource) ||
        //          (basil.sayl.EXTENSIONS.includes(c.resource) && basil.sayl.hasExtension(c.resource))
        // })

        let title = fqn === 'profile' ? getters.username : null
        
        return {
          active: m.module === cm,
          children,
          description: `partner.${fqn}_description`,
          home: basil.get(m, 'module.manifest.home'),
          fqn: fqn,
          icon: basil.get(m, 'module.manifest.icon'),
          icons: basil.get(m, 'module.manifest.icons'),
          label: `partner.${fqn}`,
          title,
          version: basil.get(m, 'module.manifest.version.version', '0.0.0')
        }
      })
      return ret
    },

    /**
     * @property {String} plans
     *  The plans of the current user
     * @readonly
     */
    plans: state => basil.get(state, 'bootstrap.project.plans', []),

    /**
     * @property {String} role
     *  The current role of the current user
     * @readonly
     */
    role: state => basil.get(state, 'bootstrap.user.profile.metadata.front_role', 'admin'),
    
    /**
     * @property {Array.<String>} timezones
     *  The list of available timezones
     * @readonly
     */
    timezones: state => basil.get(state, 'bootstrap.timezone_identifiers', []),
    
    /**
     * @property {Object} user 
     *  The authenticated user info
     * @readonly
     */
    user: state => basil.get(state, 'bootstrap.user', null),

    /**
     * @property {String} username
     *  The authenticated user name
     * @readonly
     */
    username: (state, getters) => {
      let lastname = basil.get(getters.user, 'profile.lastname', '').toUpperCase();
      let firstname = basil.get(getters.user, 'profile.name', '');

      return `${firstname} ${lastname}`
    }
  },

  mutations: {

    /**
     * Set the bootstrap data
     * 
     * @param {Object} value 
     */
    setBootstrap:(state, value) => state.bootstrap = value,
    
    /**
     * Set the burger state
     * 
     * @param {Boolean} value 
     */
    setBurger:(state, value) => state.burger = value === true,

    /**
     * Set the countries state
     * 
     * @param {Array} countries 
     */
    setCountries:(state, value) => state.countries = value,

    /**
     * Set the gmap loaded state
     * 
     * @param {Boolean} value 
     */
    setGmapLoaded: (state, value) => state.gmapLoaded = value,

    /**
     * Set the list of resources
     * 
     * @param {Array} value 
     */
    setResources: (state, value) => state.resources = value,

    setRoute: (state, { current, parent }) => {
      state.currentRoute = current
      state.parentRoute = parent
    },

    /**
     * Set the list of roles
     * 
     * @param {Array.<Role>} value 
     */
    setRoles: (state, value) => state.roles = value,

    /**
     * Set the status of the application
     * 
     * @param {Number} value 
     */
    setState: (state, value) => state.state = value,

    /**
     * Toggle a fav entry
     * 
     * @param {String} value 
     */
    toggleFavEntry(state, value) {
      if (state.favs.includes(value)) {
        let i = state.favs.indexOf(value);
        state.favs.splice(i, 1);
      }
      else {
        state.favs.push(value);
      }

      localStorage.setItem('sayl.favs', JSON.stringify(state.favs));
    },
  }
}
