export const ACLRoles = [
  { name: "admin" },
  { name: "marketing" },
  { name: "shop" },
  { name: "staff" }
]

export const ACLResources = [
  { name: "audience.triggers" },
  { name: "audience.workflows_events" },
  { name: "audience.workflows_crons" },

  { name: "audience.events" },
  { name: "audience.segments" },
  { name: "audience.utm" },

  { name: "booking.kpi" },

  { name: "cms.blocks" },
  { name: "configuration.tags" },
  { name: "configuration.admins" },
  { name: "configuration.project" },

  { name: "customer.customers" },
  
  { name: "dashboard.kpi" },

  { name: "ecommerce.catalogs" },
  { name: "ecommerce.products" },
  { name: "ecommerce.options" },
  { name: "ecommerce.attributes" },
  { name: "ecommerce.inventory" },
  { name: "ecommerce.display_rules" },
  { name: "ecommerce.associations" },
  { name: "ecommerce.vouchers" },

  { name: 'loyalty.program' },

  { name: "order.sales" },

  { name: "feedback.feedbacks" },
  { name: "feedback.entries" },

  // { name: "interaction.interaction" },
  // { name: "lightspeed.settings" },
  // { name: "mailchimp.settings" },
  // { name: "sendgrid.settings" },
  // { name: "vonage.settings" },

  { name: "settings.overview" },
  { name: "settings.shops" },
  { name: "settings.pos" },
  { name: "settings.askemma" },
  { name: "settings.embeds" },
  { name: "settings.seats" },
  { name: "settings.vats" },
]

export const ACLRules = [
  { 
    access: "allow", 
    role: "admin", 
    resources: null,
  },

  { 
    access: "disallow", 
    role: "staff", 
    resources: null,
  },

  {
    access: "allow",
    role: "marketing",
    resources: [
      "audience.workflows_events",
      "audience.workflows_crons",
      "audience.segments",
      "audience.events",
      "audience.triggers",
      "audience.utm",
      "booking.kpi",
      "cms.blocks",
    ],
    privileges: ["create", "view", "edit", "delete"]
  },
  
  {
    access: "allow",
    role: "marketing",
    resources: [
      "customer.customers",
      "order.sales",
      "dashboard.kpi",
      "ecommerce.catalogs",
      "ecommerce.products",
      "ecommerce.options",
      "ecommerce.inventory",
      "ecommerce.attributes",
      "ecommerce.display_rules",
      "ecommerce.associations",
      "ecommerce.vouchers",
      "loyalty.program",
      "feedback.entries",
    ],
    privileges: ["view"]
  },

  {
    access: "allow",
    role: "shop",
    resources: [
      "eatin.locations",
      "ecommerce.catalogs",
      "ecommerce.products",
      "ecommerce.options",
      "ecommerce.inventory",
      "ecommerce.attributes",
      "ecommerce.display_rules",
      "ecommerce.associations",
      "ecommerce.vouchers",
      "embed.embeds",
      "lightspeed.settings",
      "settings.overview",
      "settings.seats",
      "settings.embeds",
      "settings.vats"
    ],
    privileges: ["create", "view", "edit", "delete"]
  }
]
