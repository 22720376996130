<template>
  <!-- Filters -->
  <div class="ui-filters">
    <!-- ROW -->
    <div class="ui-filters__row">
      <!-- RESET -->
      <actions-button
        class="ui-filters__reset"
        icon-post="redo"
        :size="$pepper.Size.S"
        @click="onReset"
        v-if="hasReset"
      />

      <!-- Filters -->
      <div 
        class="ui-filters__filters"
        v-if="hasFilters">
        <filter-item
          class="ui-filters__filter"
          :filter="f"
          :key="f.key"
          :index="i"
          :show-popin="i === target"
          @click="onFilter(f, i)"
          @change="(values) => onFilterChange(f, values, i)"
          v-for="(f, i) in iFilters"
        />
      </div>

      <div 
        class="ui-filters__actions actions-group" 
        v-if="actions.length > 0">
        <actions-button 
          class="filters__action"
          :key="$basil.uniqId(index)"
          :icon-pre="action.icon"
          :size="$pepper.Size.S"
          v-bind="action"
          v-on="action.events"
          v-for="(action, index) in actions"
        >{{ action.label }}</actions-button>
      </div>
    </div>

    <div 
      class="ui-filters__row ui-filters__values" 
      v-if="hasValues">
      <div 
        :key="`fvalue_${index}`"
        v-for="(filter, index) in filtersWithValues">
        <data-tag 
          class="ui-filters__value"
          closable 
          size="s"
          :key="`fvalue_${index}_${j}`"
          v-for="(value, j) in filter.values"
          @close="filter.events.close(value)"
        >{{ value.label }}</data-tag>
      </div>
    </div>
  </div>
</template>

<script>
import FilterItem from './filter-item'

export default {
  name: 'UiFilters',

  components: {
    FilterItem,
  },

  props: {
    actions: {
      default: () => [],
      type: Array,
    },
    
    filters: {
      default: () => [],
      type: Array,
    },

    hasReset: {
      default: true,
      type: Boolean, 
    },
  },

  data() {
    return {
      filter: null,
      target: null,
      destroy: false,
    }
  },

  computed: {
    hasFilters() {
      return this.$basil.get(this.iFilters, 'length', 0) > 0
    },

    hasTarget() {
      return !this.$basil.isNil(this.target)
    },

    hasValues() {
      return this.filtersWithValues
        .filter(f => f.required !== true)
        .filter(f => f.values.length > 0)
        .length > 0
    },

    iFilters() {
      return (this.filters || []).map((f, index) => {
        f.key = this.$basil.uniqId(index)
        return f
      })
    },

    filtersWithValues() {
      return this.iFilters
        .filter(f => f.required !== true)
        .filter(f => f.type !== Date)
    }
  },

  methods: {
    onFilter(filter, i) {
      this.filter = this.filter === filter ? null : filter;
      this.target = this.target === i ? null : i;
    },

    onFilterChange(f, values) {
      this.$basil.get(f, 'events.change', () => {})(values)
      this.onFilter(f, null)
    },

    onReset() {
      this.$emit('reset')
    }
  },
}
</script>
