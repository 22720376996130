import { oauth } from './'
import { config } from '@/env'

export default {

  /**
   * Start the authentication process with the oauth server
   * 
   * @returns {Promise}
   */
  authenticate() {
    let url = [config.transports.cp.oauth, 'initialize'].join('');
    return oauth.post(url, { redirect: window.location.href })
      .then((response) => {
        window.location = response.data.redirect;
      }).catch((error) => {
        // handle error
        console.error('bootstrap.api.authenticate failed with message: ', error.message);
      })
  },

  /**
   * Triggers the users logout
   */
  logout() {
    let url = [config.transports.cp.oauth, 'forget'].join('');
    
    return oauth.post(url)
      .then(() => window.location = '/')
      .catch((error) => console.error(error))
  }
}
