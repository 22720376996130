const LIST = [
  {
    active: false,
    description: 'partner.clients_description',
    fqn: 'sayl-partner-home',
    href: { name: 'sayl-partner_home' },
    icon: 'users',
    label: 'partner.clients_title',
    locked: false,
    subs: []
  },
  // {
  //   active: false,
  //   description: 'sayl-partner.clients_description',
  //   fqn: 'sayl-partner-clients',
  //   href: { name: 'sayl-partner_clients' },
  //   icon: 'users',
  //   label: 'sayl-partner.clients',
  //   locked: false,
  //   subs: []
  // },
  // {
  //   active: false,
  //   description: 'sayl-customers.description',
  //   fqn: 'sayl-customer',
  //   href: { name: 'sayl-customer_home' },
  //   icon: 'user',
  //   label: 'sayl-customer.title',
  //   subs: [
  //     'sayl-customer_entry',
  //     'sayl-customer_entry-overview',
  //     'sayl-customer_entry-fields',
  //     'sayl-companies_home',
  //     'sayl-companies_item',
  //   ],
  //   locked: false,
  // },
  // {
  //   active: false,
  //   description: 'sayl-integration.description',
  //   fqn: 'sayl-integrations',
  //   icon: 'layout-grid-add',
  //   icons: ['ghost', 'workflow', 'bullhorn'],
  //   label: 'sayl-integration.title', 
  //   href: { name: 'sayl-integration_home' },
  //   locked: false,
  //   subs: [
  //     'sayl-integration_config', 
  //     'sayl-integration_presentation', 
  //   ],
  // },
  // {
  //   active: false,
  //   description: 'sayl.configure_description',
  //   fqn: 'sayl-configure',
  //   icon: 'adjustments',
  //   icons: ['building-store', 'cog', 'flag'],
  //   label: 'sayl.configure_title',
  //   locked: false,
  //   subs: [],
  // },
  // {
  //   active: false,
  //   description: 'sayl.cms_description',
  //   fqn: 'sayl-cms',
  //   href: { name: 'sayl-configuration_blocks' },
  //   icon: 'browser',
  //   label: 'sayl-cms.title',
  //   locked: false,
  //   subs: ['sayl-configuration_block'],
  // },
  // {
  //   active: false,
  //   description: 'sayl-settings.users_description',
  //   fqn: 'sayl-configure-users',
  //   href: { name: 'sayl-configuration_users' },
  //   label: 'sayl-settings.users',
  //   locked: false,
  //   subs: ['sayl-configuration_user'],
  // },
  // {
  //   active: false,
  //   description: 'sayl-settings.customer_description',
  //   fqn: 'sayl-configure-project',
  //   href: { name: 'sayl-configuration_project' },
  //   label: 'sayl-settings.customer',
  //   locked: false,
  //   subs: [],
  // },
  // {
  //   active: false,
  //   description: 'sayl-settings.tags_description',
  //   fqn: 'sayl-configure-tags',
  //   href: { name: 'sayl-configuration_tags' },
  //   label: 'sayl-settings.tags',
  //   locked: false,
  //   subs: ['sayl-configuration_tag'],
  // },
  // {
  //   active: false,
  //   description: 'sayl.vat_description',
  //   fqn: 'sayl-configure-vats',
  //   href: { name: 'sayl-configuration_vats' },
  //   label: 'sayl.vat_title',
  //   locked: false ,
  //   subs: ['sayl-configuration_vat'],
  // },
  // {
  //   active: false,
  //   description: 'sayl-configuration.webhooks_description',
  //   fqn: 'sayl-configure-webhooks',
  //   href: { name: 'sayl-configuration_webhooks' },
  //   label: 'sayl-configuration.webhooks_title',
  //   locked: false,
  //   subs: ['sayl-configuration_webhook'],
  // },

  // {
  //   active: false,
  //   description: 'sayl-configuration.public_api_tokens_description',
  //   fqn: 'sayl-configure-public-api-tokens',
  //   href: { name: 'sayl-configuration_public-api-tokens' },
  //   label: 'sayl-configuration.public_api_tokens',
  //   locked: false,
  //   subs: ['sayl-configuration_public-api-token'],
  // },
]

export default LIST
