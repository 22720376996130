import { version } from '../package.json'

const config = {
  env: process.env.VUE_APP_ENV || 'production',
  transports: {
    cp: {
      api: process.env.VUE_APP_API,
      oauth: process.env.VUE_APP_OAUTH,
    },

    module: process.env.VUE_APP_MODULE,
    preview: !!process.env.VUE_APP_PREVIEW,
    next: !!process.env.VUE_APP_NEXT,
  },
  version: {
    client: version
  },

  cdn: process.env.VUE_APP_CDN || 'https://cdn.sayl.cloud/partner/develop/',

  redirect: {
    url: process.env.VUE_APP_REDIRECT || 'https://dev.app.sayl.cloud/'
  }
}

const isDevelopment = config.env === 'development'
const isStaging = config.env === 'staging'
const isProduction = config.env === 'production'

export {
  config,
  isDevelopment,
  isStaging,
  isProduction
}
