import { basil } from '@spices/basil'

/**
 * Register Google Map
 * 
 * @param {Object} options
 * @param {GingerCapabilities} options.capabilities
 * @returns {Promise.resolve}
 */
export default function ({ capabilities }) {
  return new Promise((resolve) => {
    $console.group('bootstrap.gmap')

    let apikey = basil.get($dl.bootstrapData, 'google.map.key', null);
    let map = new GoogleMap({ 
      apikey, 
      eventbus: capabilities.eventbus, 
      store: capabilities.store 
    });
    capabilities.eventbus.$on('gmap.load', map.load.bind(map));

    $console.groupEnd('bootstrap.gmap')
    resolve()
  })
}

class GoogleMap {
  constructor({ apikey, eventbus, store }) {
    this.apikey = apikey;
    this.eventbus = eventbus;
    this.store = store;
    this.id = 'google-maps-sdk';

    this.loaded = false;

    this.container = document.body;
  }

  load() {
    if (this.loaded) {
      this.eventbus.$emit('googleMaps.loaded');
      return;
    }

    const js = document.createElement('script');
    js.id = this.id;
    js.onload = () => {
      this.loaded = true;
      this.store.commit('sayl/setGmapLoaded', true);
      this.eventbus.$emit('gmap.loaded');
    };
    js.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${this.apikey}`;
    this.container.appendChild(js);
  }
}
