/**
 * @property {Object} Formats
 */
const Formats = {};

let prefix = 'sayl.format_';

/**
 * Formats format CSV 
 * @property {Object} CSV
 */
const CSV = {
  description: `${prefix}csv_description`,  
  label: `${prefix}csv`, 
  value: 'csv', 
};
Formats.CSV = CSV;

/**
 * Formats format PDF
 * @property {Object} PDF
 */
const PDF = {
  description: `${prefix}pdf_description`,
  label: `${prefix}pdf`, 
  value: 'pdf', 
};
Formats.PDF = PDF;

/**
 * Formats Format Excel (XLSX)
 * @property {Object} EXCEL
 */
const EXCEL = {
  description: `${prefix}xlsx_description`, 
  label: `${prefix}xlsx`,
  value: 'xlsx', 
};
Formats.EXCEL = EXCEL;

/**
 * All values for the Templates
 * @property {Array} ALL
 */
Formats.ALL = [
  CSV,
  EXCEL,
  PDF
];

export default Formats
