import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      userPlans: 'sayl/plans'
    }),

    canViewPage() {
      let products = this.$basil.get(this.$route, 'meta.requires.values', null)
      
      if(!basil.isNil(products)) {
        let canView = products.find(p => this.userPlans.isProductActive(p) || p.product === '*')
        return !this.$basil.isNil(canView)
      }

      return true
    },
  },

  methods: {
    hasProduct(name) {
      return this.userPlans.isProductActive({ product: name })
    },

    canViewComponent(feature = null, product = null) {
      let products = this.$basil.get(this.$route, 'meta.requires.values', [])
      
      if(!basil.isNil(products)) {
        let prod = products.find(p => {
          let ret = false

          if(!this.$basil.isNil(product)) {
            ret = this.userPlans.isProductActive({ product })
          }

          if(!this.$basil.isNil(feature) && ret) {
            ret = this.userPlans.isFeatureActive(p.product, feature)
          }

          return ret
        })
        
        return !this.$basil.isNil(prod)
      }

      return true
    },

    isFeatureAvailable(route) {
      let products = this.$basil.get(route, 'meta.requires.values', [])
      
      if(!basil.isNil(products)) {
        let canView = products.find(p => this.userPlans.isProductActive(p) || p.product === '*')
        return !this.$basil.isNil(canView)
      }

      return true
    },

    featureCurrent(product, feature) {
      return this.userPlans.getCurrent(product, feature)
    },

    featureLimit(product, feature) {
      return this.userPlans.getLimit(product, feature)
    },

    getFeature(product, feature) {
      return this.userPlans.getFeature(product, feature)
    },

    getPlan(product) {
      return this.userPlans.getPlan(product)
    }
  }
}
