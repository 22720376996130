<template>
  <nav 
    :class="classes"
    @mouseenter="onEnter"
    @mouseleave="onLeave">
    <div class="n-primary__display">
      
      <!-- Top Pane -->
      <primary-top-pane 
        :active="!isLevel2"
        :navigation="navigation"
        :opened="isOpen"
        :route-entry="routeEntry"
        :selection="selection"
        :x="x"
        :y="y"
        @selection="onSelection" 
        />

      <!-- Sub Panes -->
      <transition-group
        name="level2-entries"
        tag="div" 
        class="n-primary__inner"
        mode="out-in">

        <primary-module-pane
          v-for="e in entries"
          :key="e.fqn"
          :entry="e"
          @close="onEntryClose"
          />

      </transition-group>

    </div>
    
    <!-- Identity -->
    <footer class="n-primary__identity">
      <button 
        class="n-primary__user n-primary__entry"
        :class="{ 
          '-is-impersonating': isImpersonating, 
        }"
        id="identity"
        @click="onSelection( navigation.identity )">
        <!-- Icon -->
        <ui-icon
          class="n-primary__icon" 
          glyph="users"/>

        <!-- Labels -->
        <div class="n-primary__identify">
          <span 
            v-if="!impersonated"
            class="n-primary__customer"
            :title="currentCustomerName">{{ currentCustomerName }}</span>
          <span 
            class="n-primary__master"
            :title="currentUsername">{{ currentUsername }}</span>
          <span 
            v-if="impersonated"
            class="n-primary__impersonate"
            :title="impersonated">{{ impersonated }}</span>
        </div>

        <!-- Icons -->
        <ui-icon 
          class="n-primary__sort"
          glyph="sort" />
      </button>
    </footer>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { MixinImpersonation } from '@sayl/admin-common'

import PrimaryTopPane from './primary-top-pane'
import PrimaryModulePane from './primary-module-pane'

import Navigation from './navigation/index'

export default {
  name: 'PrimaryNavigation',

  mixins: [
    MixinImpersonation,
    Navigation
  ],

  components: {
    PrimaryModulePane,
    PrimaryTopPane,
  },

  data(){
    return {
      /**
       * @property {Array} entries
       *  The list of sub-children to display.
       *  Filtered version of the `iEntries` list.
       */
      entries: [],

      /**
       * @property {Boolean} isOpen
       *  Whether or not the menu is closed or open
       */
      isOpen: false,

      /**
       * @property {Boolean} isInteractive
       *  Whether or not we listen for mouse input. Only usefull for debugging purposes.
       */
      isInteractive: true,

      /**
       * @property {Boolean} pristine
       *  Whether or not this is a virgin. Will true itself when touch for the very first time.
       *  Madona la la la. (Not christine and the queens ;) ).
       */
      pristine: true,

      /**
       * @property {Object} route
       *  Current route. Info from the $router.
       */
      route: null,

      /**
       * @property {Object} route
       *  Current route. The user has click on a section. 
       */
      route: null,

      /**
       * @property {Object} selection
       *  Current selected entry.
       */
      selection: null,
    }
  },

  computed: {
    ...mapState({
      module: state => state.ginger.module,
    }),

    ...mapGetters({
      // navigation: 'sayl/navigation',
    }),

    classes(){
      return {
        'n-primary': true,
        '-is-opened': this.isOpen,  // whether or not view the full width
        '-is-level2': this.isLevel2 // whether or not we are in a sub-child
      }
    },
    
    /**
     * @property {String} fqn
     * Current FQN
     *  1. From the selection
     *  2. From the route
     */
    fqn(){
      return this.selectionFQN || this.routeFQN
    },

    iEntries(){
      let ret = []

      // X
      this.navigation.body.forEach( x => {
        x.children.forEach( y => {

          // Y
          if (y.children){
            ret.push(y)
          }
        })
      })

      // Identity
      ret.push( this.navigation.identity )

      return ret
    },

    /**
     * @property {Boolean} isLevel2
     *  Whether or not we are in level2
     */
    isLevel2(){
      return this.entries.length > 0
    },

    /**
     * @property {Object} routeEntry
     *  Current entry. Same as current `route` but with the navigation info.
     */
    routeEntry(){
      let ret = null
      let source = [this.navigation.body, this.navigation.footer].flat()
      
      // X => Sayl
      source.forEach( x => {
        x.children.forEach( y => {

          // Y => Integrations
          let yn = this.$basil.get(y, 'href.name')
          let sb = this.$basil.get(y, 'subs', null)

          if (yn && this.routeName.includes(yn) || (!this.$basil.isNil(sb) && sb.includes(this.routeName))) {
            ret = y
          }

          // Z => Overview
          if (y.children){
            y.children.forEach( z => {
              let zn = this.$basil.get(z, 'href.name')
              let sb = this.$basil.get(z, 'subs', null)

              if (zn && this.routeName.includes(zn) || (!this.$basil.isNil(sb) && sb.includes(this.routeName))) {
                ret = z
              }
            })
          }
        })
      })

      return ret
    },
    
    routeName(){
      return this.$basil.get(this.route, 'name', '');
    },

    routeFQN(){
      return this.$basil.get(this.routeEntry, 'fqn', null)
    },

    selectionFQN(){
      return this.$basil.get(this.selection, 'fqn', null)
    },

    /**
     * @property {Object} x
     *  Displayed X level.
     *  1. Based from the selection
     *  2. Based from the route 
     *  3. Nulled
     */
    x(){ 
      let y = this.$basil.get(this.y, 'parent', null)

      return this.$basil.get(this.selection, 'depth', 0) === 0 ? this.selection : null ||
             y.depth === 0 ? y : null
    },

    /**
     * @property {Object} y
     *  Displayed Y level.
     *  1. Based from the selection
     *  2. Based from the route 
     *  3. Nulled
     */
    y(){
      return this.$basil.get(this.selection, 'depth', 0) === 1 ? this.selection : null ||
             this.$basil.get(this.z, 'parent', null) ||

             this.$basil.get(this.routeEntry, 'depth', 0) === 1 ? this.routeEntry : null ||
             this.$basil.get(this.routeEntry, 'parent', null)
    },

    /**
     * @property {Object} z
     *  Displayed Z level.
     *  1. Based from the selection
     *  2. Based from the route 
     *  3. Nulled
     */
    z(){
      return this.$basil.get(this.selection, 'depth', 0) === 2 ? this.selection : null ||
             this.$basil.get(this.routeEntry, 'depth', 0) === 2 ? this.routeEntry : null
    }
  },

  watch: {
    '$router.currentRoute': {
      handler: function(n, o){
        this.route = n
        this.reset()
      },
      immediate: true
    },
  },

  methods: {
    /**
     * Reset
     * Compare the current route name with the one in the navigation list.
     * Compute the values
     */
    reset(){
      if (this.$basil.isNil(this.fqn)){
        console.warn('Warn: No FQN could be matched for the primary navigation')
        return
      }

      // 
      //  Update the flags in the master tree
      //  -> Active = Based on the route fqn
      //  -> Selected = Based on the selection
      // 

      let cx = null
      let cy = null
      let cz = null
      let source = [
        this.navigation.body, 
        this.navigation.footer,
        this.navigation.identity
      ].flat()

      // X => Sayl
      // this.navigation.body.forEach( x => {
      source.forEach( x => {
        x.depth = 0

        x.children.forEach( y => {
          y.depth = 1
          y.parent = x

          // Y => Integrations
          let active = false
          let selected = false
          if (y.children){

            // Z => Overview
            y.children.forEach( z => {
              z.depth = 2
              z.parent = y

              z.active = z.fqn === this.routeFQN
              z.selected = z.fqn === this.selectionFQN

              active = z.active || active
              selected = z.selected || selected

              if (z.active === true){
                cz = z
              }
            })
          }

          y.active = active || y.fqn === this.routeFQN
          y.selected = selected || y.fqn === this.selectionFQN

          if (y.selected === true){
            cy = y
            cx = x
          }

          // If selection is null (no selection) then 
          // the selection is set to the matching y route level
          if (y.active && !this.selection && this.pristine){
            this.selection = y
          }
        })
      })


      // 
      //  Update the displayed items / sub-items
      //
      let fqn = this.$basil.get(cy, 'fqn') || this.$basil.get(this.selection, 'fqn')
      this.entries = fqn ? this.iEntries.filter(e => e.fqn === fqn) : []

      // Update the store 
      if(!this.$basil.isNil(this.selection)) {
        if(!this.$basil.isNil(this.selection.children) && this.selection.children.length > 0) {
          let child = this.selection.children.find(c => c.active)
  
          if(child) {
            this.$store.commit('sayl/setRoute', { current: child, parent: this.selection.parent})
          }
        } else if(this.$basil.isNil(this.selection.children)) {
          this.$store.commit('sayl/setRoute', { current: this.selection, parent: this.selection.parent})
        }
      }

      this.pristine = false
    },

    /**
     * MouseEnter Handler
     */
    onEnter(){
      if (!this.isInteractive) {
        return
      }
      
      this.isOpen = true
    },

    /**
     * MouseLeave Handler
     */
    onLeave(){
      if (!this.isInteractive) {
        return
      }

      this.isOpen = false
    },

    /**
     * Request to close the current entry
     */
    onEntryClose(){
      this.selection = null
      this.entries = []
      this.reset()
    },

    /**
     * Request to change the current selection
     */
    onSelection(entry){
      this.selection = entry
      this.reset()
    }
  },

  mounted(){
    this.reset();
  }
}
</script>
