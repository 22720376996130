import Chart from 'chart.js'
import RoundedBar from '../views/ui/components/charts/rounded-bar'

export default function ({capabilities}) {
  $console.group('utils-chart')

  RoundedBar(Chart)

  $console.groupEnd('utils-chart')
  return Promise.resolve()
}